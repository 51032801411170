import React, { Component, Fragment  } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SolicitarMtoCorrectivo.css';

import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft, FaPlus, FaCogs, FaCheck } from "react-icons/fa";

import { postSolicitudMtoCorrectivo, clearErrors } from '../../../redux/actions/dataactions';

export class SolicitarMtoCorrectivo extends Component {
    constructor(){
        super();
        this.state = {
            fallareportada: '',
            errors: {},
            open: false,
            aprobado: {}
        };
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
        if (props.UI.aprobado) {
            newState.aprobado = props.UI.aprobado;
        }
        return Object.keys(newState).length > 0 ? newState : null;
    }

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        if (this.state.aprobado.mensaje) {
            this.props.clearErrors();
            this.setState({
                open: false,
                errors: {},
                fallareportada: '',
                aprobado: {}
            });
        } else {
            this.props.clearErrors();
            this.setState({open: false, errors: {}});
        }
    };
    
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let fechasolicitud = Date.now();

        const newSolicitudMto = {
            fallareportada: this.state.fallareportada,
            idprestador: this.props.user.credentials.idprestador,
            idusersolicita: this.props.user.credentials.userId,
            handlesolicita: this.props.user.credentials.handle,
            nombresolicita: this.props.user.credentials.nombre,
            nombreequipo: this.props.equipo.nombre,
            marca: this.props.equipo.marca,
            serie: this.props.equipo.serie,
            modelo: this.props.equipo.modelo,
            handleuser: this.props.equipo.ingeniero,
            idinventario: this.props.equipo.idinventario,
            ubicacion: this.props.equipo.ubicacion,
            servicio: this.props.equipo.servicio,
            equipoid: this.props.equipoid,
            fechasolicitudmilis: fechasolicitud,
            telnotificacion: this.props.user.credentials.telefono,
            emailnotificacion: this.props.user.credentials.email
        };

        this.props.postSolicitudMtoCorrectivo(newSolicitudMto, this.props.equipoid);
    }

    render() {
        const { UI: { loading }} = this.props;
        const { errors } = this.state;

        return (
            <div>
                <Fragment>
                    <MyButton tip="Solicitar Mantenimiento Correctivo" onClick={this.handleOpen}>
                        <FaCogs color="#03178C" className="icon-crearcliente"/>
                    </MyButton>

                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        fullWidth
                        maxWidth="sm"
                    >
                        {this.state.aprobado.mensaje ? 
                            (<></>) : 
                            (<DialogTitle>Solicitar Mantenimiento Correctivo</DialogTitle>)
                        }

                        <DialogContent>
                            {this.state.aprobado.mensaje ? (
                                <div className='div-aprobado'>
                                    <div className='div-icono-aprobado'>
                                        <FaCheck color="#ffffff" className='icono-aprobado' />
                                    </div>
                                    <h2 className='text-aprobado'>{this.state.aprobado.mensaje}</h2>
                                </div>
                            ) : (
                                <form>
                                    <TextField
                                        className="login-input-email"
                                        required
                                        type="text"
                                        name="fallareportada"
                                        id="fallareportada"
                                        label="Describa la Falla"
                                        value={this.state.fallareportada}
                                        onChange={this.handleChange}
                                        min="2019-01-01" max="2022-01-01"
                                        fullWidth
                                        helperText={errors.fallareportada}
                                        error={errors.fallareportada ? true : false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </form>
                            )}
                        </DialogContent>

                        <DialogActions>
                            <MyButton tip="Cerrar" onClick={this.handleClose}>
                                <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
                            </MyButton>

                            {loading ? (
                                <CircularProgress size={30} color="primary" className="login-progress" />
                            ) : this.state.aprobado.mensaje ? (
                                <></>
                            ) : (
                                <MyButton tip="Solicitar Mantenimiento Correctivo" onClick={this.handleSubmit}>
                                    <FaPlus color="#03178C" className="icon-formulario"/>
                                </MyButton>
                            )}
                        </DialogActions>
                    </Dialog>
                </Fragment>
            </div>
        )
    }
}

SolicitarMtoCorrectivo.propTypes = {
    postSolicitudMtoCorrectivo: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    equipo: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    UI: state.UI,
    user: state.user,
    equipo: state.data.equipo.credentials,
    data: state.data
});

export default connect(mapStateToProps, {postSolicitudMtoCorrectivo, clearErrors})(SolicitarMtoCorrectivo);