import React,{ useState, useEffect } from 'react';
import PropTypes from 'prop-types'; 
import Button from '../../Elements/Button'
import { Link } from 'react-router-dom';
import './Topbar.css';
import { FaBars, FaBookReader, FaFileMedical, FaFirstAid, FaNotesMedical, FaShoppingCart, FaThLarge, FaTimes, FaUserFriends, FaUserNurse, FaUsersCog } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import imgsrc from '../../Navbar/logohorizontal.svg';
import {connect} from 'react-redux';
import {logoutDashboard} from '../../../redux/actions/useractions';
import MyButton from '../../../utils/MyButton';
import { FaPowerOff } from "react-icons/fa";

function Topbar(props) {
   
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
      setIsMobile(true);
    } else {
      setButton(true);
      setIsMobile(false);
    }
  };

  const { user } = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 960);

  useEffect(() => {showButton()}, []);

  window.addEventListener('resize', showButton);

  const handleLogout=()=>{
    props.logoutDashboard(props.history); 
  }

  return (
    <div>
      <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className='navbar'>
          <div className='navbar-container container'>
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
              <img src={imgsrc} alt="logo" className='logonavbar'/>
            </Link> 

            <div className='menu-icon' onClick={handleClick}>
              {click ? <FaTimes color="#03178C"/> : <FaBars color="#03178C"/>}
            </div>

            <div className={click ? 'nav-menudash active' : 'nav-menudash'}>
              {isMobile ? (
                <div className='container-navbar'>
                  <div className="sidebar-profile">
                    <img src={user.credentials.imageUrl} alt="profile" className="profile-image"/>
                    <h3 className="sidebar-nombre">{user.credentials.nombre}</h3>
                    <h3 className="sidebar-handle">@{user.credentials.handle}</h3>
                    <hr className="sidebar-separador"></hr>
                  </div>

                  {user.credentials.tipouser=="Asistencial" ? (
                    <div className="sidebar-list">
                      <div className="sidebar-item">
                        <Link to="/dashboard" className="sidebar-link" onClick={closeMobileMenu}>
                          <div className="sidebar-linkdiv">
                            <FaThLarge color="#0468BF" className="sidebar-icon"/>
                            <p className="sidebar-text">Mi Dashboard</p>
                          </div>                                       
                        </Link>
                      </div>
                      
                      <div className="sidebar-item">
                        <Link to="/dashboard/equiposasistencial" className="sidebar-link" onClick={closeMobileMenu}>
                          <div className="sidebar-linkdiv">
                            <FaFirstAid color="#0468BF" className="sidebar-icon"/>
                            <p className="sidebar-text">Equipos Biomedicos</p>
                          </div>                                       
                        </Link>
                      </div>

                      <div className="sidebar-item">
                        <Link to="/dashboard/solicitudesasistencial" className="sidebar-link" onClick={closeMobileMenu}>
                          <div className="sidebar-linkdiv">
                            <FaFileMedical color="#0468BF" className="sidebar-icon"/>
                            <p className="sidebar-text">Compras</p>
                          </div>                                       
                        </Link>
                      </div>

                      <div className="sidebar-item">
                        <Link to="/dashboard/infrasasistencial" className="sidebar-link" onClick={closeMobileMenu}>
                          <div className="sidebar-linkdiv">
                            <FaFirstAid color="#0468BF" className="sidebar-icon"/>
                            <p className="sidebar-text">Infraestructura</p>
                          </div>                                       
                        </Link>
                      </div>

                      

                      <div className="sidebar-item">
                        <Link to="/dashboard/miperfil" className="sidebar-link" onClick={closeMobileMenu}>
                          <div className="sidebar-linkdiv">
                            <FaUserNurse color="#0468BF" className="sidebar-icon"/>
                            <p className="sidebar-text">Mi Perfil</p>
                          </div>                                       
                        </Link>
                      </div>
                    </div>
                  ) : null}

                  {user.credentials.tipouser==="Ingeniero" ? (
                    <div className="sidebar-list">
                      <div className="sidebar-item">
                        <Link to="/dashboard" className="sidebar-link" onClick={closeMobileMenu}>
                          <div className="sidebar-linkdiv">
                            <FaThLarge color="#0468BF" className="sidebar-icon"/>
                            <p className="sidebar-text">Mi Dashboard</p>
                          </div>                                       
                        </Link>
                      </div>

                      <div className="sidebar-item">
                        <Link to="/dashboard/clientes" className="sidebar-link" onClick={closeMobileMenu}>
                          <div className="sidebar-linkdiv">
                            <FaNotesMedical color="#0468BF" className="sidebar-icon"/>
                            <p className="sidebar-text">Prestadores</p>
                          </div>                                       
                        </Link>
                      </div>

                      <div className="sidebar-item">
                        <Link to="/dashboard/misrepuestos" className="sidebar-link" onClick={closeMobileMenu}>
                          <div className="sidebar-linkdiv">
                            <FaUsersCog color="#0468BF" className="sidebar-icon"/>
                            <p className="sidebar-text">Mis Repuestos</p>
                          </div>                                       
                        </Link>
                      </div>

                      <div className="sidebar-item">
                        <Link to="/dashboard/homemarket" className="sidebar-link" onClick={closeMobileMenu}>
                          <div className="sidebar-linkdiv">
                            <FaShoppingCart color="#0468BF" className="sidebar-icon"/>
                            <p className="sidebar-text">Marketplace</p>
                          </div>                                       
                        </Link>
                      </div>

                    

                      <div className="sidebar-item">
                        <Link to="/dashboard/cursos" className="sidebar-link" onClick={closeMobileMenu}>
                          <div className="sidebar-linkdiv">
                            <FaBookReader color="#0468BF" className="sidebar-icon"/>
                            <p className="sidebar-text">Educación</p>
                          </div>                                       
                        </Link>
                      </div>

                      <div className="sidebar-item">
                        <Link to="/dashboard/miperfil" className="sidebar-link" onClick={closeMobileMenu}>
                          <div className="sidebar-linkdiv">
                            <FaUserNurse color="#0468BF" className="sidebar-icon"/>
                            <p className="sidebar-text">Mi Perfil</p>
                          </div>                                       
                        </Link>
                      </div>
                    </div>
                  ) : null}
                    
                  <MyButton tip="Cerrar Sesión" onClick={handleLogout}>
                    <FaPowerOff color="#03178C" className="icon-logout"/>
                  </MyButton>
                </div>
              ) : (
                <MyButton tip="Cerrar Sesión" onClick={handleLogout}>
                  <FaPowerOff color="#03178C" className="icon-logout"/>
                </MyButton>
              )}
            </div>
          </div>
        </nav>
      </IconContext.Provider>
    </>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionsToProps = {logoutDashboard};

Topbar.propTypes = {
  logoutDashboard: PropTypes.func.isRequired,   
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps,mapActionsToProps)(Topbar)