import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import alphalogo from '../../../utils/alphahorizontal.png';

const createStyles = (primaryColor = '#005695', secondaryColor = '#0067b2') => {
  // Color palette
  const colors = {
    primary: primaryColor,
    secondary: secondaryColor,
    gray: {
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      800: '#1F2937',
    },
    white: '#FFFFFF'
  };

  return StyleSheet.create({
    // Content Layout
    content: {
      flexDirection: 'row',
      gap: 20,
    },
    leftColumn: {
      width: '40%',  // Aumentado de 35% a 40%
    },
    rightColumn: {
      width: '60%',  // Ajustado de 65% a 60%
    },
    
    // Prestador Logo
    prestadorLogoContainer: {
      alignItems: 'center',
      marginBottom: 10,
    },
    prestadorLogo: {
      width: 110,
      height: 90,
      borderRadius: 6,
      objectFit: 'contain',
      borderWidth: 1,
      borderColor: colors.gray[200],
      backgroundColor: colors.gray[50],
      padding: 4,
    },
    
    // Section Styles
    section: {
      backgroundColor: colors.white,
      padding: 5,
      borderRadius: 8,
      marginBottom: 8,
      borderWidth: 1,
      borderColor: colors.gray[200],
    },
    sectionTitle: {
      backgroundColor: colors.primary,
      padding: '8 12',
      borderRadius: 4,
      marginBottom: 5,
    },
    sectionTitleText: {
      fontSize: 10,
      color: colors.white,
      fontWeight: 'bold',
    },
    
    // Equipment Section Styles
    equipmentContent: {
      flexDirection: 'row',
      gap: 10,
    },
    equipmentImageContainer: {
      width: '35%',
    },
    equipmentImage: {
      width: '100%',
      height: 120,
      borderRadius: 6,
      objectFit: 'contain',
      borderWidth: 1,
      borderColor: colors.gray[200],
      backgroundColor: colors.gray[50],
      padding: 4,
    },
    equipmentInfo: {
      flex: 1,
      gap: 2,
    },
    
    // Data Display
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 6,
      gap: 10,
    },
    label: {
      fontSize: 8,
      color: colors.gray[800],
      fontWeight: 'bold',
      width: '35%',
    },
    
    // Images
    image: {
      width: 100,
      height: 100,
      borderRadius: 6,
      marginBottom: 12,
      objectFit: 'cover',
      borderWidth: 1,
      borderColor: colors.gray[200],
    },
    // Base page setup
    page: {
      backgroundColor: colors.white,
      padding: 30,
      paddingBottom: 60,
      fontFamily: 'Helvetica',
    },

    // Header styles
    header: {
      backgroundColor: colors.white,
      padding: 15,
      borderRadius: 8,
      marginBottom: 15,
      borderWidth: 1,
      borderColor: colors.gray[200],
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    headerContent: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 20,
    },
    headerLogoContainer: {
      backgroundColor: colors.white,
      padding: 8,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.gray[200],
      width: '30%',
    },
    headerLogo: {
      width: '100%',
      height: 40,
      objectFit: 'contain',
    },
    headerTitle: {
      backgroundColor: colors.primary,
      padding: '8 12',
      borderRadius: 6,
      width: '40%',
    },
    headerTitleText: {
      fontSize: 11,
      color: colors.white,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    headerInfo: {
      width: '25%',
      gap: 6,
    },
    headerInfoItem: {
      backgroundColor: colors.gray[50],
      padding: '6 10',
      borderRadius: 4,
      marginBottom: 4,
    },
// Maintenance Checks
checkGrid: {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 6,
},

checkLabel: {
  fontSize: 8,
  color: colors.gray[800],
  fontWeight: 'bold',
  flex: 1,
},
checkValue: {
  fontSize: 8,
  fontWeight: 'bold',
  marginLeft: 8,
},
checkGrid: {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 8,
},
checkItem: {
  width: '48%',
  backgroundColor: colors.gray[50],
  padding: '6 8',
  borderRadius: 4,
  marginBottom: 2,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderWidth: 1,
  borderColor: colors.gray[200],
},
checkLabel: {
  fontSize: 8,
  color: colors.gray[800],
  fontWeight: 'bold',
  flex: 1,
},
checkValue: {
  fontSize: 8,
  fontWeight: 'bold',
  marginLeft: 8,
},
checkValueTrue: {
  fontSize: 8,
  fontWeight: 'bold',
  marginLeft: 8,
  color: colors.primary,
},
checkValueFalse: {
  fontSize: 8,
  fontWeight: 'bold',
  marginLeft: 8,
  color: colors.gray[800],
},
    // Footer styles
    footer: {
      position: 'absolute',
      bottom: 20,
      left: 30,
      right: 30,
      borderTopWidth: 1,
      borderColor: colors.gray[200],
      paddingTop: 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    footerLogo: {
      width: 120,
      height: 24,
      objectFit: 'contain',
    },
    footerText: {
      fontSize: 8,
      color: colors.gray[800],
    },
    footerWeb: {
      fontSize: 9,
      color: colors.primary,
      fontWeight: 'bold',
    },

    observationsContainer: {
      gap: 4,
    },
    observationsLabel: {
      fontSize: 8,
      color: colors.gray[800],
      fontWeight: 'bold',
    },
    observationsBox: {
      backgroundColor: colors.gray[50],
      padding: 8,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: colors.gray[200],
      minHeight: 97,
    },
    observationsText: {
      fontSize: 8,
      color: colors.gray[800],
      lineHeight: 1.4,
    },
    timeRow: {
      flexDirection: 'column',
      gap: 6,
      marginBottom: 10,
    },
    timeItem: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: colors.gray[50],
      padding: '6 8',
      borderRadius: 4,
      borderWidth: 1,
      borderColor: colors.gray[200],
    },

    // Common text styles
    text: {
      fontSize: 8,
      color: colors.gray[800],
    },
    value: {
      fontSize: 8,
      color: colors.gray[800],
      flex: 1,
    },

    // Technical Tests Styles
    testsContainer: {
      flexDirection: 'row',
      gap: 1,
      backgroundColor: colors.white,
      padding: 2,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: colors.gray[200],
    },
    testHalf: {
      flex: 1,
      padding: 1,
      backgroundColor: colors.white,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: colors.gray[200],
    },
    testRow: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 2,
      gap: 2,
    },
    approvedValue: {
      fontSize: 8,
      color: colors.primary,
      fontWeight: 'bold',
      flex: 1,
    },
    notApprovedValue: {
      fontSize: 8,
      color: colors.gray[800],
      fontWeight: 'bold',
      flex: 1,
    },

      // Personnel Section Styles
      personnelSection: {
        marginTop: 2,
        height: 120,
      },
      personnelContainer: {
        flexDirection: 'row',
        gap: 2,
        backgroundColor: colors.white,
        padding: 2,
        border:'none',
        height: '100%',
      },
      personnelHalf: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.white,
        padding: 2,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: colors.gray[200],
        gap: 10,
      },
      signatureContainer: {
        width: '40%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.gray[50],
        borderRadius: 4,
        borderWidth: 1,
        borderColor: colors.gray[200],
      },
      signatureImage: {
        width: '90%',
        height: '90%',
        objectFit: 'contain',
      },
      noSignatureText: {
        fontSize: 8,
        color: colors.gray[800],
        fontStyle: 'italic',
      },
      personnelInfo: {
        flex: 1,
        gap: 4,
      },
      infoItem: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 4,
      },
      labelPersonnel: {
        fontSize: 10,
        color: colors.gray[800],
        fontWeight: 'bold',
        marginBottom: 4,
      },
      roleText: {
        fontSize: 9,
        color: colors.primary,
      }
  });
};

const ReporteMtoDocuPDF = ({ equipo, cliente, mtopreventivo }) => {
  if (!equipo || !cliente || !mtopreventivo) {
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>No hay información disponible</Text>
          </View>
        </Page>
      </Document>
    );
  }

  const styles = createStyles(cliente.primaryColor, cliente.secondaryColor);
  const imagenUrl = cliente.imagenurl || 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media';
  const imagenEquipoUrl = equipo.credentials.imagenurl || 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media';

  const Footer = () => (
    <View style={styles.footer} fixed>
      <View>
        <Image src={alphalogo} style={styles.footerLogo} />
      </View>
      <View>
        <Text style={styles.footerWeb}>www.alphabiomedica.com</Text>
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.headerContent}>
            <View style={styles.headerLogoContainer}>
              <Image src={cliente.imagenurl} style={styles.headerLogo} />
            </View>
            <View style={styles.headerTitle}>
              <Text style={styles.headerTitleText}>REPORTE DE MANTENIMIENTO PREVENTIVO</Text>
            </View>
            <View style={styles.headerInfo}>
              <View style={styles.headerInfoItem}>
              <Text style={styles.value}>Reporte No: {(mtopreventivo.progid || '').toString().slice(0, 6) || 'N/A'}</Text>
              </View>
              <View style={styles.headerInfoItem}>
                <Text style={styles.value}>Fecha: {mtopreventivo.fecha || 'N/A'}</Text>
              </View>
            </View>
          </View>
        </View>

        {/* Main Content */}
        <View style={styles.content}>
          {/* Left Column - Client Information */}
          <View style={styles.leftColumn}>
            <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text style={styles.sectionTitleText}>INFORMACIÓN DEL PRESTADOR</Text>
              </View>
              <View style={styles.prestadorLogoContainer}>
                <Image src={imagenUrl} style={styles.prestadorLogo} />
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>NOMBRE:</Text>
                <Text style={styles.value}>{cliente.nombre}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>CC/NIT:</Text>
                <Text style={styles.value}>{cliente.ccnit}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>CIUDAD:</Text>
                <Text style={styles.value}>{cliente.ciudad}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>DIRECCIÓN:</Text>
                <Text style={styles.value}>{cliente.direccion}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>TELÉFONO:</Text>
                <Text style={styles.value}>{cliente.telefono}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>EMAIL:</Text>
                <Text style={styles.value}>{cliente.email}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>SEDE:</Text>
                <Text style={styles.value}>{cliente.sede}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>CÓDIGO REPS:</Text>
                <Text style={styles.value}>{cliente.codigoreps}</Text>
              </View>
            </View>

             {/* Execution Times and Observations */}
             <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text style={styles.sectionTitleText}>DETALLES DE EJECUCIÓN</Text>
              </View>
              <View style={styles.executionDetails}>
                <View style={styles.timeRow}>
                  <View style={styles.timeItem}>
                    <Text style={styles.label}>FECHA:</Text>
                    <Text style={styles.value}>{mtopreventivo.fecha || 'N/A'}</Text>
                  </View>
                  <View style={styles.timeItem}>
                    <Text style={styles.label}>HORA INICIO:</Text>
                    <Text style={styles.value}>{mtopreventivo.horainicio || 'N/A'}</Text>
                  </View>
                  <View style={styles.timeItem}>
                    <Text style={styles.label}>HORA FINAL:</Text>
                    <Text style={styles.value}>{mtopreventivo.horafinal || 'N/A'}</Text>
                  </View>
                </View>
                <View style={styles.observationsContainer}>
                  <Text style={styles.observationsLabel}>OBSERVACIONES:</Text>
                  <View style={styles.observationsBox}>
                    <Text style={styles.observationsText}>
                      {mtopreventivo.observaciones || 'Ninguna observación registrada'}
                    </Text>
                  </View>
                </View>
                </View>
                </View>
          </View>

          {/* Right Column - Equipment Information */}
          <View style={styles.rightColumn}>
            <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text style={styles.sectionTitleText}>INFORMACIÓN DEL EQUIPO</Text>
              </View>
              <View style={styles.equipmentContent}>
                <View style={styles.equipmentImageContainer}>
                  <Image src={imagenEquipoUrl} style={styles.equipmentImage} />
                </View>
                <View style={styles.equipmentInfo}>
                  <View style={styles.row}>
                    <Text style={styles.label}>NOMBRE:</Text>
                    <Text style={styles.value}>{equipo.credentials.nombre}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>ID INVENTARIO:</Text>
                    <Text style={styles.value}>{equipo.credentials.idinventario}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>MARCA:</Text>
                    <Text style={styles.value}>{equipo.credentials.marca}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>MODELO:</Text>
                    <Text style={styles.value}>{equipo.credentials.modelo}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>SERIE:</Text>
                    <Text style={styles.value}>{equipo.credentials.serie}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>UBICACIÓN:</Text>
                    <Text style={styles.value}>{equipo.credentials.ubicacion}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>SERVICIO:</Text>
                    <Text style={styles.value}>{equipo.credentials.servicio}</Text>
                  </View>
                </View>
              </View>
            </View>

              {/* Maintenance Checks */}
              <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text style={styles.sectionTitleText}>ACTIVIDADES DE MANTENIMIENTO</Text>
              </View>
              <View style={styles.checkGrid}>
                {[
                  { label: 'INSPECCIÓN CHASIS', value: mtopreventivo.chasis },
                  { label: 'VERIFICACIÓN DE ACCESORIOS', value: mtopreventivo.vaccesorios },
                  { label: 'PRUEBA CUANTITATIVA', value: mtopreventivo.pcuantitativa },
                  { label: 'VERIFICACIÓN DE CONTROLES', value: mtopreventivo.vcontroles },
                  { label: 'SEGURIDAD ELÉCTRICA', value: mtopreventivo.selectrica },
                  { label: 'LIMPIEZA', value: mtopreventivo.limpieza },
                  { label: 'LIMPIEZA CHASIS', value: mtopreventivo.lchasis },
                  { label: 'LUBRICACIÓN DE PARTES', value: mtopreventivo.lubricacion },
                  { label: 'LIMPIEZA ELECTRÓNICA', value: mtopreventivo.lelectronica },
                  { label: 'VERIFICACIÓN GENERAL', value: mtopreventivo.vgeneral }
                ].map((item, index) => (
                  <View key={index} style={styles.checkItem}>
                    <Text style={styles.checkLabel}>{item.label}</Text>
                    <Text style={item.value ? styles.checkValueTrue : styles.checkValueFalse}>
                      {item.value ? 'Si' : 'No'}
                    </Text>
                  </View>
                ))}
              </View>
            </View>

            {/* Technical Tests */}
            <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text style={styles.sectionTitleText}>PRUEBAS TÉCNICAS</Text>
              </View>
              
              <View style={styles.testsContainer}>
                {/* Test 1 */}
                <View style={styles.testHalf}>
                  <View style={styles.testRow}>
                    <Text style={styles.label}>PRUEBA:</Text>
                    <Text style={styles.value}>{mtopreventivo.prueba1 || 'No Registra'}</Text>
                  </View>
                  <View style={styles.testRow}>
                    <Text style={styles.label}>INSTRUMENTO:</Text>
                    <Text style={styles.value}>{mtopreventivo.instrumento1 || 'N/A'}</Text>
                  </View>
                  <View style={styles.testRow}>
                    <Text style={styles.label}>VALOR:</Text>
                    <Text style={styles.value}>{mtopreventivo.valor1 || 'N/A'}</Text>
                  </View>
                  <View style={styles.testRow}>
                    <Text style={styles.label}>APROBÓ:</Text>
                    <Text style={mtopreventivo.aprobo1 ? styles.approvedValue : styles.notApprovedValue}>
                      {mtopreventivo.aprobo1 ? 'Si' : 'No'}
                    </Text>
                  </View>
                </View>

                {/* Test 2 */}
                <View style={styles.testHalf}>
                  <View style={styles.testRow}>
                    <Text style={styles.label}>PRUEBA:</Text>
                    <Text style={styles.value}>{mtopreventivo.prueba2 || 'No Registra'}</Text>
                  </View>
                  <View style={styles.testRow}>
                    <Text style={styles.label}>INSTRUMENTO:</Text>
                    <Text style={styles.value}>{mtopreventivo.instrumento2 || 'N/A'}</Text>
                  </View>
                  <View style={styles.testRow}>
                    <Text style={styles.label}>VALOR:</Text>
                    <Text style={styles.value}>{mtopreventivo.valor2 || 'N/A'}</Text>
                  </View>
                  <View style={styles.testRow}>
                    <Text style={styles.label}>APROBÓ:</Text>
                    <Text style={mtopreventivo.aprobo2 ? styles.approvedValue : styles.notApprovedValue}>
                      {mtopreventivo.aprobo2 ? 'Si' : 'No'}
                    </Text>
                  </View>
                </View>
                </View>

                </View>
              
            </View>
        </View>
         {/* Personnel Information */}
         {/* Personnel Information */}
         <View style={styles.personnelSection}>
          <View style={styles.sectionTitle}>
            <Text style={styles.sectionTitleText}>INFORMACIÓN DEL PERSONAL</Text>
          </View>
          <View style={styles.personnelContainer}>
            {/* Recipient */}
            <View style={styles.personnelHalf}>
              <View style={styles.signatureContainer}>
                {cliente.imagenfirma ? (
                  <Image src={cliente.imagenfirma} style={styles.signatureImage} />
                ) : (
                  <Text style={styles.noSignatureText}>Pendiente firma</Text>
                )}
              </View>
              <View style={styles.personnelInfo}>
                <View style={styles.infoItem}>
                  <Text style={styles.labelPersonnel}>{mtopreventivo.firmarecibe || 'N/A'}</Text>
                  <Text style={styles.roleText}>Coordinador</Text>
                </View>
              </View>
            </View>

            {/* Engineer */}
            <View style={styles.personnelHalf}>
              <View style={styles.signatureContainer}>
                {mtopreventivo.firmaIngeniero ? (
                  <Image src={mtopreventivo.firmaIngeniero} style={styles.signatureImage} />
                ) : (
                  <Text style={styles.noSignatureText}>Pendiente firma</Text>
                )}
              </View>
              <View style={styles.personnelInfo}>
                <View style={styles.infoItem}>
                  <Text style={styles.labelPersonnel}>{mtopreventivo.handleuser || 'N/A'}</Text>
                  <Text style={styles.roleText}>Ingeniero</Text>
                </View>
              </View>
            </View>
          </View>
        </View>


        {/* Footer */}
        <Footer />
      </Page>
    </Document>
  );
};

export default ReporteMtoDocuPDF;