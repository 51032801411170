import React, { useEffect,useState } from 'react';
import './HojadeVidaEquipo.css'; 
import MyButton from '../../../utils/MyButton';
import { FaFileMedical,FaArrowCircleLeft, FaCogs, FaFilePdf, FaPhoneSquareAlt, FaRegCalendarCheck, FaRocket, FaSortDown, FaSortUp, FaThermometerHalf, FaWrench} from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import {getEquipo} from '../../../redux/actions/dataactions';
import ListaMtoProgAsis from './ListaMtoProgAsis';
import HistorialPrevAsis from './HistorialPrevAsis';
import HistorialCorrectivosAsis from './HistorialCorrectivosAsis';
import ListaCalProgAsis from './ListaCalProgAsis';
import ListaCalibracionesAsis from './ListaCalibracionesAsis';
import SolicitarMtoCorrectivo from './SolicitarMtoCorrectivo';
import HistorialSolicitudes from './HistorialSolicitudes';
import { Button } from '@material-ui/core';
import RecomendacionesAsis from './RecomendacionesAsis';
import CrearCasoTecno from '../Tecnovigilancia/crearCasoTecno';
import TablaTecno from '../Tecnovigilancia/TablaTecno';

function HojadeVidaEquipo({ getEquipo, data, match }) {
  const history = useHistory();
  
  useEffect(() => {
    console.log(match.params.equipoId);
    getEquipo(match.params.equipoId);
  }, [getEquipo, match.params.equipoId]);

  const { equipo, loading } = data;
  const [perfilInfoVisible, setPerfilInfoVisible] = useState(false); // Estado para controlar la visibilidad

  const [mostrarComponente, setMostrarComponente] = useState(false); // Estado para controlar la visibilidad del componente

  const [accion, setAccion] = useState(null);
  const [expandedInfo, setExpandedInfo] = useState(false);  // Estado para controlar la información expandida
  const [activeComponent, setActiveComponent] = useState(false);

  const toggleAccion = (nuevaAccion) => {
    if (nuevaAccion === accion && mostrarComponente) {
      // Si se hace clic en el botón que ya está seleccionado y el componente está visible, ocultarlo
      setAccion("");
      setMostrarComponente(false);
    } else {
      setAccion(nuevaAccion);
      setMostrarComponente(true);
    }
  };

  const handleBack = () => {
    history.goBack();
  };


    let cargarEquipo= !loading ? (
      <div className="equipment-card">
        <div className="equipment-content">
          <div className='equipment-img-container'>
          <div className="div-imagencliente">
            <img
                src={
                  equipo.credentials.imagenurl === undefined || equipo.credentials.imagenurl === "undefined"
                    ? 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media'
                    : equipo.credentials.imagenurl
                }
                alt="equipo"
                className="img-cliente"
              />

            </div>
          </div>
       

            <div className="equipment-info-wrapper">
            <div className="div-titulocliente">
       
              <h3 className="text-titulo-cliente">{equipo.credentials.nombre}</h3>
              <p className="perfilequipo-text">{equipo.credentials.estado}</p>
              <p className="perfilequipo-text">{equipo.credentials.idinventario}</p>
              </div>
            

          <div className="equipment-details-container">
            <div className="equipment-details-section">
              <hr className="details-separator" />

              <div className="detail-row">
                <h5 className="detail-label">Modelo: </h5>
                <p className="detail-value">{equipo.credentials.modelo}</p>
              </div>

            <div className="detail-row">
            <h5 className="detail-label">Serie </h5>    
            <p className="detail-value">{equipo.credentials.serie}</p>
            </div>

            <div className="detail-row">
            <h5 className="detail-label">Registro Invima </h5>    
            <p className="detail-value">{equipo.credentials.registroinvima}</p>
            </div>

            <div className="detail-row">
            <h5 className="detail-label">Clase de Aislamiento </h5>    
            <p className="detail-value">{equipo.credentials.clase}</p>
            </div>

            <div className="detail-row">
            <h5 className="detail-label">Ubicación</h5>    
            <p className="detail-value">{equipo.credentials.ubicacion}</p>
            </div>

            <div className="detail-row">
            <h5 className="detail-label">Servicio</h5>    
            <p className="detail-value">{equipo.credentials.servicio}</p>
            </div>

            <div className="detail-row">
            <h5 className="detail-label">Equipo</h5>    
            <p className="detail-value">{equipo.credentials.movilfijo}</p>
            </div>

            <div className="detail-row">
            <h5 className="detail-label">Tipo </h5>    
            <p className="detail-value">{equipo.credentials.tipo}</p>
            </div>

            <div className="detail-row">
            <h5 className="detail-label">Clasificación del Riesgo</h5>    
            <p className="detail-value">{equipo.credentials.riesgo}</p>
            </div>

            <div className="detail-row">
            <h5 className="detail-label">Clasificación Biomédica </h5>    
            <p className="detail-value">{equipo.credentials.clasbiomedica}</p>
            </div>

             </div>

      
             <div className="div-perfil-info" style={{ display: perfilInfoVisible ? 'flex' : 'none' }}>
            <hr className="clientes_separator"></hr>  
            
            <div className="detail-row">
            <h5 className="detail-label">Forma de Adquisición </h5>    
            <p className="detail-value">{equipo.credentials.adquisicion}</p>
            </div>     

            <div className="detail-row">
            <h5 className="detail-label">Costo </h5>    
            <p className="detail-value">${equipo.credentials.costo}</p>
            </div>        

            <div className="detail-row">
            <h5 className="detail-label">Vida Util </h5>    
            <p className="detail-value">{equipo.credentials.vidautil} años</p>
            </div>        

            <div className="detail-row">
            <h5 className="detail-label">Fecha de Fabricación </h5>    
            <p className="detail-value">{equipo.credentials.fabricacion}</p>
            </div>   
            
            <div className="detail-row">
            <h5 className="detail-label">Inicio de Operación</h5>    
            <p className="detail-value">{equipo.credentials.operacion}</p>
            </div>   

            <div className="detail-row">
            <h5 className="detail-label">Proveedor</h5>    
            <p className="detail-value">{equipo.credentials.proveedor}</p>
            </div>  

            <div className="detail-row">
            <h5 className="detail-label">E-mail</h5>    
            <p className="detail-value">{equipo.credentials.proveedoremail}</p>
            </div>  

            <div className="detail-row">
            <h5 className="detail-label">Teléfono</h5>    
            <p className="detail-value">{equipo.credentials.proveedortel}</p>
            </div>  

            </div>


            <div className="equipment-extended-info" style={{ display: expandedInfo ? 'flex' : 'none' }}>
            <hr className="details-separator" />

            <div className="detail-row">
            <h5 className="detail-label">Tipo de Tecnología</h5>    
            <p className="detail-value">{equipo.credentials.tipotec}</p>
            </div>  

            <div className="detail-row">
            <h5 className="detail-label">Fuente de Alimentación</h5>    
            <p className="detail-value">{equipo.credentials.fuente}</p>
            </div>  

            <div className="detail-row">
                <h5 className="detail-label">Link del Manual</h5>    
                <p className="detail-value">{equipo.credentials.urlmanual==='Sin Url'?<a>Sin Manual Adjunto</a>:<a href={equipo.credentials.urlmanual} target="_blank" rel="noopener noreferrer">Link del Manual</a>}</p>
                </div> 

                <div className="detail-row">
                <h5 className="detail-label">Link de Factura</h5>    
                <p className="detail-value">{equipo.credentials.urlfactura==='Sin Url'?<a>Sin Factura Adjunta</a>:<a href={equipo.credentials.urlfactura} target="_blank" rel="noopener noreferrer">Link de la Factura</a>}</p>
                </div> 

            <div className="detail-row">
            <h5 className="detail-label">Tensión Min</h5>    
            <p className="detail-value">{equipo.credentials.tensionmin} V</p>
            </div>  

            <div className="detail-row">
            <h5 className="detail-label">Tensión Max</h5>    
            <p className="detail-value">{equipo.credentials.tensionmax} V</p>
            </div> 

            <div className="detail-row">
            <h5 className="detail-label">Corriente Min</h5>    
            <p className="detail-value">{equipo.credentials.corrientemin} A</p>
            </div>

            <div className="detail-row">
            <h5 className="detail-label">Corriente Max</h5>    
            <p className="detail-value">{equipo.credentials.corrientemax} A</p>
            </div>

            <div className="detail-row">
            <h5 className="detail-label">Potencia</h5>    
            <p className="detail-value">{equipo.credentials.potencia} W</p>
            </div>  

            <div className="detail-row">
            <h5 className="detail-label">Temperatura</h5>    
            <p className="detail-value">{equipo.credentials.temperatura} °C</p>
            </div>  

            <div className="detail-row">
            <h5 className="detail-label">Frecuencia</h5>    
            <p className="detail-value">{equipo.credentials.frecuencia} °C</p>
            </div>  

            <div className="detail-row">
            <h5 className="detail-label">Presión</h5>    
            <p className="detail-value">{equipo.credentials.presion}</p>
            </div>  

            <div className="detail-row">
            <h5 className="detail-label">Alto</h5>    
            <p className="detail-value">{equipo.credentials.alto} cm</p>
            </div>  

            <div className="detail-row">
            <h5 className="detail-label">Ancho</h5>    
            <p className="detail-value">{equipo.credentials.ancho} cm</p>
            </div>  

            <div className="detail-row">
            <h5 className="detail-label">Largo</h5>    
            <p className="detail-value">{equipo.credentials.largo} cm</p>
            </div>  

            <div className="detail-row">
            <h5 className="detail-label">Peso</h5>    
            <p className="detail-value">{equipo.credentials.peso} Kg</p>
            </div>  

            </div>


           </div>
            </div>
            </div>

            <div className="equipment-actions">
              <RecomendacionesAsis clienteId equipoId={match.params.equipoId} />
              <CrearCasoTecno clienteId={match.params.clienteId} equipoId={match.params.equipoId} />
              <SolicitarMtoCorrectivo equipoid={match.params.equipoId} />
              
              <Link to={`/dashboard/inventarioequiposasistencial/hojadevidapdf/${equipo.credentials.idprestador}/${match.params.equipoId}`} 
                    className="pdf-link">
                <MyButton tip="Hoja de Vida PDF">
                  <FaFilePdf color="#03178C" className="action-icon" />
                </MyButton>
              </Link>

              <MyButton tip="Mostrar Más" onClick={() => setExpandedInfo(!expandedInfo)}>
                {expandedInfo ? (
                  <FaSortUp color="#03178C" className="action-icon" />
                ) : (
                  <FaSortDown color="#03178C" className="action-icon" />
                )}
              </MyButton>
            </div>
         
        </div>

        
        )
        
        :( <div className="loading-container">
          <CircularProgress size={50} color="primary" className="loading-spinner" />
        </div>);
        
        const handleVolverClick = () => {
          history.goBack();
    };  

    return (
      <div className="equipment-profile-container">
      <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Perfil Equipo</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <MyButton tip="Volver" onClick={handleVolverClick}>
                    <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
                  </MyButton>
        </div>
        </div>
    
        {cargarEquipo}
        <div className="action-buttons-container">
        <Button
            variant="contained"
            endIcon={<FaWrench style={{ color: accion === "MtosProg" ? "white" : "#03178c" }} />}
            onClick={() => toggleAccion("MtosProg")}
            style={{
                backgroundColor: accion === "MtosProg" ? "#0588FB" : "initial",
                color: accion === "MtosProg" ? "white" : "#03178c",
                marginRight:accion === "MtosProg" ? "10px" : "10px",
                textTransform: accion === "MtosProg" ? "initial" : "initial"
                
            }}
            >
            {accion === "MtosProg" ? "Mtos Programados" : "Mtos. Programados"}
            </Button>
        <Button
          variant="contained"
          endIcon={<FaRocket  style={{ color: accion === "MtosReal" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("MtosReal")} // Actualizar el estado al hacer clic en "MtosReal"
          style={{
            backgroundColor: accion === "MtosReal" ? "#0588FB" : "initial",
            color: accion === "MtosReal" ? "white" : "#03178c",
            marginRight:accion === "MtosReal" ? "10px" : "10px",
            textTransform: accion === "MtosReal" ? "initial" : "initial"
            
        }}
        >
          {accion === "MtosReal" ? "Mtos. Realizados" : "Mtos. Realizados"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaRegCalendarCheck  style={{ color: accion === "CalProg" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("CalProg")} // Actualizar el estado al hacer clic en "CalProg"
          style={{
            backgroundColor: accion === "CalProg" ? "#0588FB" : "initial",
            color: accion === "CalProg" ? "white" : "#03178c",
            marginRight:accion === "CalProg" ? "10px" : "10px",
            textTransform: accion === "CalProg" ? "initial" : "initial"
            
        }}
        >
          {accion === "CalProg" ? "Cal. Programadas" : "Cal. Programadas"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaThermometerHalf  style={{ color: accion === "CalReal" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("CalReal")}// Actualizar el estado al hacer clic en "CalReal"
          style={{
            backgroundColor: accion === "CalReal" ? "#0588FB" : "initial",
            color: accion === "CalReal" ? "white" : "#03178c",
            marginRight:accion === "CalReal" ? "10px" : "10px",
            textTransform: accion === "CalReal" ? "initial" : "initial"
            
        }}
        >
          {accion === "CalReal" ? "Cal. Realizadas" : "Cal. Realizadas"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaCogs  style={{ color: accion === "MtosCorr" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("MtosCorr")} // Actualizar el estado al hacer clic en "MtosCorr"
          style={{
            backgroundColor: accion === "MtosCorr" ? "#0588FB" : "initial",
            color: accion === "MtosCorr" ? "white" : "#03178c",
            marginRight:accion === "MtosCorr" ? "10px" : "10px",
            textTransform: accion === "MtosCorr" ? "initial" : "initial"
            
        }}
        >
          {accion === "MtosCorr" ? "Mtos. Correctivos" : "Mtos. Correctivos"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaPhoneSquareAlt  style={{ color: accion === "SolCorr" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("SolCorr")} // Actualizar el estado al hacer clic en "SolCorr"
          style={{
            backgroundColor: accion === "SolCorr" ? "#0588FB" : "initial",
            color: accion === "SolCorr" ? "white" : "#03178c",
            marginRight:accion === "SolCorr" ? "10px" : "10px",
            textTransform: accion === "SolCorr" ? "initial" : "initial"
            
        }}
        >
          {accion === "SolCorr" ? "Sol. Correctivos" : "Sol. Correctivos"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaFileMedical style={{ color: accion === "Tecnovigilancia" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("Tecnovigilancia")} // Actualizar el estado al hacer clic en "Tecnovigilancia"
          style={{
            backgroundColor: accion === "Tecnovigilancia" ? "#0588FB" : "initial",
            color: accion === "Tecnovigilancia" ? "white" : "#03178c",
            marginRight: accion === "Tecnovigilancia" ? "10px" : "10px",
            textTransform: accion === "Tecnovigilancia" ? "initial" : "initial"

          }}
        >
          {accion === "Tecnovigilancia" ? "Casos Tecnovigilancia" : "Casos Tecnovigilancia"}
        </Button>
      </div>
      <div className="components-container">
        {mostrarComponente && (
            <div className="active-component-wrapper">

             <div>
             {accion === "MtosProg" ? <div>
             <ListaMtoProgAsis equipoId={match.params.equipoId} />
             </div> : null}
             </div>

            <div>
            {accion === "MtosReal" ? <div>
            <HistorialPrevAsis equipoId={match.params.equipoId} />
             </div> : null}
            </div>

            <div>
            {accion === "CalProg" ? <div>
            <ListaCalProgAsis equipoId={match.params.equipoId} />
               </div> : null}
            </div>

            <div>
              {accion === "CalReal" ? <div>
             
        <ListaCalibracionesAsis equipoId={match.params.equipoId} />
              </div> : null}
            </div>

            <div>
              {accion === "MtosCorr" ? <div>
              <HistorialCorrectivosAsis equipoId={match.params.equipoId} />
              </div> : null}
            </div>

            <div>
              {accion === "SolCorr" ? <div>
              <HistorialSolicitudes equipoId={match.params.equipoId} />
              </div> : null}
            </div>

            <div>
              {accion === "Tecnovigilancia" ? <div>
                <TablaTecno clienteId={match.params.clienteId} equipoId={match.params.equipoId} />
              </div> : null}
            </div>

           </div>

        )}
       
      

        


       
      </div>

        
        </div>

    )
  }


HojadeVidaEquipo.propTypes = {
    getEquipo: PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired
  };

const mapActionsToProps={getEquipo};

const mapStateToProps = (state)=>({
    data:state.data,
    user:state.user
})



export default connect(mapStateToProps,mapActionsToProps)(HojadeVidaEquipo);

