

import {SET_ERRORS,CLEAR_ERRORS,LOADING_UI,SET_INDICADORES,LOADING_DATA, SET_UNAUTHENTICATED, LOADING_USER,SET_USER,LOADING_PERFIL,SET_PERFIL, SET_OK, SET_TECNICOS, SET_USUARIOS, SET_USUARIOS_ASISTENCIALES} from '../types'; 
import axios from 'axios'; 

export const loginUser= (userData,history)=>(dispatch)=>{
  
    dispatch({type:LOADING_UI});
    
    
    axios
    .post('/login',userData)
    .then(res=>{
        setAuthorizationHeader(res.data.token);
        dispatch(getUserData());
        dispatch(getIndicadores());
        
        dispatch({type:CLEAR_ERRORS});
        
        //redirecciona al home
        history.push('/dashboard');
    })
    .catch((err)=>{
       dispatch({
           type:SET_ERRORS,
           payload:err.response.data
       })
    });
}


export const getIndicadores=()=> dispatch =>{
  console.log('entré a indicadores');
  dispatch({type:LOADING_DATA});
  axios.get('/indicadores')
  .then(res=>{
      dispatch({
          type:SET_INDICADORES,
          payload:res.data
      });
      
      dispatch({type:CLEAR_ERRORS});
      
  }).catch(err=>{
      dispatch({
          type: SET_ERRORS,
          payload:[]
      })
  })
};

export const getIndicadoresTecnico=(userhandle)=> dispatch =>{
  console.log('entré a indicadores');
  dispatch({type:LOADING_DATA});
  axios.get(`/indicadorestecnico/${userhandle}`)
  .then(res=>{
      dispatch({
          type:SET_INDICADORES,
          payload:res.data
      });
      
      dispatch({type:CLEAR_ERRORS});
      
  }).catch(err=>{
      dispatch({
          type: SET_ERRORS,
          payload:[]
      })
  })
};

export const signupUser= (newUserData,history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    
    axios
    .post('/signup',newUserData)
    .then(res=>{
        setAuthorizationHeader(res.data.token); 
        dispatch(getUserData());
        dispatch({type:CLEAR_ERRORS});
        
        //redirecciona al home
        history.push('/crearperfil');
    })
    .catch((err)=>{
      
       dispatch({
           type:SET_ERRORS,
           payload:err.response.data
       })
    });
}

export const crearPerfilUser=(userData,history)=>(dispatch)=>{
  dispatch({type:LOADING_UI});
   
  axios.post('/crearperfil',userData)
  .then(()=>{
    dispatch(getUserData());
    
    //redirecciona al dashboard
    history.push('/dashboard');
  })
  .catch((err)=>{
    console.log('Hubo un error en crear perfil');
    
    dispatch({
      type:SET_ERRORS,
      payload:err.response.data
    })
  });
}

export const logoutUser = () => (dispatch) => {
    localStorage.removeItem('FBIdToken');
    delete axios.defaults.headers.common['Authorization'];
    dispatch({ type: SET_UNAUTHENTICATED });
  };

export const logoutDashboard = (history) => (dispatch) => {
    localStorage.removeItem('FBIdToken');
    delete axios.defaults.headers.common['Authorization'];
    dispatch({ type: SET_UNAUTHENTICATED });
  };



  export const getUserData = () => (dispatch) => {
    dispatch({ type: LOADING_USER });
    axios
      .get('/user')
      .then((res) => {
        dispatch({
          type: SET_USER,
          payload: res.data
        });
      })
      .catch((err) => console.log(err));
  };


  export const getPerfil=()=>(dispatch)=>{
    dispatch({ type: LOADING_PERFIL });
    axios
      .get('/perfil')
      .then((res) => {
        dispatch({
          type: SET_PERFIL,
          payload: res.data
        });
      })
      .catch((err) => console.log(err));
  }
  const setAuthorizationHeader=(token)=>{
        
    const FBIdToken=`Bearer ${token}`
    localStorage.setItem('FBIdToken',FBIdToken); 
    axios.defaults.headers.common['Authorization']=FBIdToken; 
    
}

export const getUsuarios = (clienteId) => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios.get(`/getusuarios/${clienteId}`)
    .then((res) => {
      dispatch({ type: SET_USUARIOS, payload: res.data });
      dispatch({ type: CLEAR_ERRORS });
      
    })
    .catch((err) => {
      console.log('Hubo error al obtener usuarios'+err.response.data.message);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data.message
      })
    });
}

export const getUsuariosAsistenciales = (clienteId) => dispatch => {
  dispatch({type:LOADING_UI});
  axios.get(`/getusuarios/${clienteId}`)
    .then((res) => {
      // Filter only asistencial users
      const usuariosAsistenciales = res.data.filter(user => user.tipouser === 'Asistencial');
      dispatch({ type: SET_USUARIOS_ASISTENCIALES, payload: usuariosAsistenciales });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log('Error al obtener usuarios asistenciales: ' + err.response.data.message);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data.message
      })
    });
}

export const agregarUsuario = (userData, clienteId) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios.post(`/agregarUsuario/${clienteId}`, userData)
    .then((res) => {
      dispatch({
        type: SET_OK,
        payload: res.data
      })
    
     
    })
    .catch((err) => {
      console.log('Hubo un error en crear perfil');
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      })
    });
}

export const editarUsuario = (data, clienteId) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios.post(`/editarUsuario`, data)
    .then((res) => {
      dispatch({
        type: SET_OK,
        payload: res.data
      })
      
      
    })
    .catch((err) => {
      console.log('Hubo un error editando el perfil');
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      })
    });
}

export const eliminarUsuario = (handle, clienteId) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios.post(`/deleteUser/${handle}`)
    .then((res) => {
      dispatch({
        type: SET_OK,
        payload: res.data
      })
    

    })

    .catch((err) => {
      console.log('Error al eliminar el usuario');
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      })
    });
}

export const getTecnicos = (handleUser) => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios.get(`/getTecnicos/${handleUser}`)
    .then((res) => {
      dispatch({
        type: SET_TECNICOS,
        payload: res.data
      });
    })
    .catch((err) => console.log(err));
}

export const addNewTecnico = (newTecnico, clienteId) => dispatch => {
  dispatch({ type: LOADING_UI });
  console.log(newTecnico, clienteId)
  axios
    .post(`/agregarnuevotecnico/${clienteId}`, newTecnico)
    .then((res) => {
      dispatch({
        type: SET_OK,
        payload: res.data
      });
      dispatch(getUsuarios(clienteId));
      console.log('Tecnico agregado correctamente');

    })
    .catch((err) => {
      console.log('Error al agregar técnico' + err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      })
    });
};

export const editPassword = (data) => dispatch => {
  dispatch({ type: LOADING_UI });
  console.log(data)
  axios.post('/editPassword', data)
    .then((res) => {
      dispatch({
        type: SET_OK,
        payload: res.data
      });
    })
    .catch((err) => {
      console.log('Error al actualizar las credenciales' + err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      })
    });
}