import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getEquipo, getCliente } from '../../../redux/actions/dataactions';
import { PDFViewer } from '@react-pdf/renderer';
import './HojaDeVidaPDF.css';
import HojaDeVidaDocuPDF2 from './HojaDeVidaDocuPDF2';
import { useHistory } from 'react-router-dom';

function HojaDeVidaPDF(props) {
  const [verPDF, setVerPDF] = useState(false);
  const history = useHistory();
  const { equipo, cliente } = props.data;

  useEffect(() => {
    props.getEquipo(props.match.params.equipoId);
    props.getCliente(props.match.params.clienteId);
  }, []);

  const handleOpenPDF = () => {
    setVerPDF(!verPDF);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const renderContent = () => {
    if (!cliente?.plantillaType) {
      return (
        <div className="hoja-vida-error">
          <p>Para ver las plantillas, primero debe configurar el tipo de plantilla en la lista de clientes</p>
        </div>
      );
    }

    if (equipo && cliente && verPDF) {
      return (
        <PDFViewer className="hoja-vida-pdf-viewer">
          <HojaDeVidaDocuPDF2 equipo={equipo} cliente={cliente} />
        </PDFViewer>
      );
    }

    return null;
  };

  return (
    <div className="hoja-vida-wrapper">
      <div className="hoja-vida-header">
        <div className="hoja-vida-back-btn">
          <MyButton tip="Volver" onClick={handleGoBack}>
            <FaArrowCircleLeft className="hoja-vida-back-icon" />
          </MyButton>
        </div>

        {cliente?.plantillaType && (
          <Button 
            variant="text"
            color="primary"
            onClick={handleOpenPDF}
          >
            {verPDF ? 'Ocultar PDF' : 'Ver PDF'}
          </Button>
        )}
      </div>

      <div className="hoja-vida-content">
        {renderContent()}
      </div>
    </div>
  );
}

HojaDeVidaPDF.propTypes = {
  getEquipo: PropTypes.func.isRequired,
  getCliente: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, { getEquipo, getCliente })(HojaDeVidaPDF);