import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getEquipos, getCliente } from '../../../redux/actions/dataactions';
import InventarioDocuPDF from './InventarioDocuPDF';
import { PDFViewer } from '@react-pdf/renderer';
import './InventarioPDF.css';
import { useHistory } from 'react-router-dom';

function InventarioPDF(props) {
  const [verPDF, setVerPDF] = useState(false);

  useEffect(() => {
    props.getEquipos(props.match.params.clienteId);
    props.getCliente(props.match.params.clienteId);
  }, [props.match.params.clienteId]);

  const handleOpenPDF = () => {
    setVerPDF(!verPDF);
  };

  const { equipos, cliente } = props.data;
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const renderContent = () => {
    if (!cliente?.plantillaType) {
      return (
        <div className="inventario-error-message">
          <p>Para ver las plantillas, primero debe configurar el tipo de plantilla en la lista de clientes</p>
        </div>
      );
    }

    if (equipos && cliente && verPDF) {
      return (
        <PDFViewer className="inventario-pdf-viewer">
          <InventarioDocuPDF equipos={equipos} cliente={cliente} />
        </PDFViewer>
      );
    }

    return null;
  };

  return (
    <div className="inventario-container">
      <div className="inventario-header">
        <div className="inventario-toolbar">
          <div className="inventario-left-section">
            <MyButton tip="Volver" onClick={handleGoBack} className="inventario-back-button">
              <FaArrowCircleLeft className="inventario-back-icon" />
            </MyButton>
          </div>

          {cliente?.plantillaType && (
            <div className="inventario-right-section">
              <Button 
                variant="outlined" 
                color="primary"
                onClick={handleOpenPDF}
                className="inventario-view-btn"
              >
                {verPDF ? 'Ocultar PDF' : 'Ver PDF'}
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="inventario-content">
        {renderContent()}
      </div>
    </div>
  );
}

InventarioPDF.propTypes = {
  getEquipos: PropTypes.func.isRequired,
  getCliente: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.data,
});

export default connect(mapStateToProps, { getEquipos, getCliente })(InventarioPDF);