import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ProgramarMto.css';

import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDropzone } from 'react-dropzone';

import { 
  FaArrowLeft, 
  FaPlus, 
  FaCheck, 
  FaCheckCircle, 
  FaPaperclip 
} from "react-icons/fa";

import {  clearErrors, subirDocumento, getEquipo } from '../../../redux/actions/dataactions';

function AdjuntarDoc(props) {
  const [open, setOpen] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState('');

  const { loading, errors, aprobado } = props.UI;

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    maxFiles: 1
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if(aprobado) {
      props.getEquipo(props.equipoId);
      setOpen(false);
    }
    setOpen(false);
  };

  const handleChange = (event) => {
    setTipoDocumento(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (!acceptedFiles || acceptedFiles.length === 0) {
      return;
    }

    if (!tipoDocumento) {
      return;
    }

    const formData = new FormData();
    formData.append("documento", acceptedFiles[0]);
    formData.append("tipoDocumento", tipoDocumento);
    
    props.subirDocumento(formData, props.clienteId, props.equipoId, props.user.credentials.handle);
  };

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Fragment>
      <MyButton tip="Adjuntar Documentos" onClick={handleOpen}>
        <FaPaperclip color="#03178C" className="icon-crearcliente"/>
      </MyButton>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Adjuntar Documento</DialogTitle>

        {aprobado ? (
          <DialogContent>
            <div className='div-aprobado'>
              <div className='div-icono-aprobado'>
                <FaCheck color="#ffffff" className='icono-aprobado'/>
              </div>
              <h2 className='text-aprobado'>¡Documento Adjuntado Exitosamente!</h2>
            </div>
          </DialogContent>
        ) : (
          <DialogContent>
            <form>
              <FormControl className="formcontrol-nuevocliente" fullWidth>
                <InputLabel>Tipo de Documento</InputLabel>
                <Select
                  required
                  name="tipoDocumento"
                  value={tipoDocumento}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  <MenuItem value="manual">Manual</MenuItem>
                  <MenuItem value="factura">Factura</MenuItem>
                </Select>
                <FormHelperText>{errors && errors.tipoDocumento}</FormHelperText>
              </FormControl>

              <div className="mt-4">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>Arrastra y suelta el archivo aquí, o da click para seleccionarlo</p>
                  <em>(Solo se permite subir un archivo PDF, DOC o DOCX)</em>
                </div>
                
                <aside>
                  {fileRejections.length > 0 && (
                    <div>
                      <h4>Solo se puede cargar un archivo</h4>
                    </div>
                  )}

                  {acceptedFiles.length > 0 && (
                    <div>
                      <h4>Archivo a cargar:</h4>
                      <ul>{acceptedFileItems}</ul>
                    </div>
                  )}
                </aside>
              </div>

              <div>
                {errors && errors.errors && (
                  errors.errors.length > 0 ? (
                    <ul>
                      {errors.errors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No se encontraron errores.</p>
                  )
                )}
              </div>
            </form>
          </DialogContent>
        )}

        <DialogActions>
          <MyButton tip="Cerrar" onClick={handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
          </MyButton>

          {loading ? (
            <CircularProgress size={30} color="primary" className="login-progress" />
          ) : (
            aprobado ? (
              <MyButton tip="Continuar" onClick={handleClose}>
                <FaCheckCircle color="#20B90C" className="icon-formulario" />
              </MyButton>
            ) : (
              <MyButton tip="Subir Documento" onClick={handleSubmit}>
                <FaPlus color="#03178C" className="icon-formulario" />
              </MyButton>
            )
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

AdjuntarDoc.propTypes = {
 
  clearErrors: PropTypes.func.isRequired,
  subirDocumento: PropTypes.func.isRequired,
  getEquipo: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  clienteId: PropTypes.string.isRequired,
  equipoId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  user: state.user,
  equipo: state.data.equipo.credentials
});

export default connect(mapStateToProps, {  clearErrors, subirDocumento, getEquipo })(AdjuntarDoc);