import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getEquipo, getCliente, getMtoPrev } from '../../../redux/actions/dataactions';
import { PDFViewer } from '@react-pdf/renderer';
import './ReporteMtoPDF.css';
import ReporteMtoDocuPDF from './ReporteMtoDocuPDF';

const ReporteMtoPDF = ({ getEquipo, getCliente, getMtoPrev, data, match }) => {
  const [verPDF, setVerPDF] = useState(false);
  const history = useHistory();

  const { equipo, cliente, mtopreventivo, loading } = data;

  useEffect(() => {
    getMtoPrev(match.params.mtoid);
    getEquipo(match.params.equipoId);
    getCliente(match.params.clienteId);
  }, [getMtoPrev, getEquipo, getCliente, match.params.mtoid, match.params.equipoId, match.params.clienteId]);

  const handleOpenPDF = () => {
    setVerPDF(!verPDF);
  };

  const goBack = () => {
    history.goBack();
  };

  const renderContent = () => {
    if (!cliente?.plantillaType) {
      return (
        <div className="mto-error-message">
          <p>Para ver las plantillas, primero debe configurar el tipo de plantilla en la lista de clientes</p>
        </div>
      );
    }

    if (equipo && cliente && verPDF) {
      return (
        <PDFViewer style={{ width: "100%", height: "90vh" }}>
          <ReporteMtoDocuPDF 
            equipo={equipo} 
            cliente={cliente} 
            mtopreventivo={mtopreventivo} 
          />
        </PDFViewer>
      );
    }

    return null;
  };

  return (
    <div className="mto-pdf-wrapper">
      <div className="mto-pdf-header">
        <div className="mto-pdf-back-btn">
          <MyButton tip="Volver" onClick={goBack}>
            <FaArrowCircleLeft className="mto-pdf-back-icon" />
          </MyButton>
        </div>
        {cliente?.plantillaType && (
          <Button 
            variant="text"
            color="primary"
            onClick={handleOpenPDF}
          >
            {verPDF ? "Ocultar PDF" : "Ver PDF"}
          </Button>
        )}
      </div>
      <div className="mto-pdf-content">
        {renderContent()}
      </div>
    </div>
  );
}

ReporteMtoPDF.propTypes = {
  getEquipo: PropTypes.func.isRequired,
  getCliente: PropTypes.func.isRequired,
  getMtoPrev: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  data: state.data
})

export default connect(mapStateToProps, { getEquipo, getCliente, getMtoPrev })(ReporteMtoPDF);