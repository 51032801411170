import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft, FaExclamationTriangle } from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PDFViewer } from '@react-pdf/renderer';
import { getCliente } from '../../../redux/actions/dataactions';
import { Button } from '@material-ui/core';
import './CronogramaCalPDF.css';
import CronogramaCalDocuPDF from './CronogramaCalDocuPDF';

const ErrorDisplay = ({ message, onReturn }) => (
  <div style={{ 
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#f8f9fa'
  }}>
    <div style={{
      backgroundColor: 'white',
      padding: '2rem',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      maxWidth: '400px',
      width: '100%',
      textAlign: 'center'
    }}>
      <FaExclamationTriangle 
        size={40} 
        style={{ 
          color: '#f44336',
          marginBottom: '1rem'
        }}
      />
      <h3 style={{
        color: '#333',
        marginBottom: '1rem',
        fontSize: '1.2rem'
      }}>
        Atención
      </h3>
      <p style={{
        color: '#666',
        marginBottom: '1.5rem',
        lineHeight: '1.5'
      }}>
        {message}
      </p>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={onReturn}
        style={{ 
          textTransform: 'none',
          padding: '8px 24px'
        }}
      >
        Volver a la página anterior
      </Button>
    </div>
  </div>
);

function CronogramaCalPDF(props) {
  const [verPDF, setVerPDF] = useState(false);
  const [hasData, setHasData] = useState(false);
  const history = useHistory();
  const { equiposcal, cliente } = props.data;

  useEffect(() => {
    props.getCliente(props.match.params.clienteId);
  }, [props.match.params.clienteId]);

  useEffect(() => {
    const hasEquipos = equiposcal && equiposcal.length > 0;
    setHasData(hasEquipos);
  }, [equiposcal]);

  const handleReturn = () => {
    history.push(`/dashboard/perfilcliente/${props.match.params.clienteId}/cronocalperfil`);
  };

  if (!hasData) {
    return (
      <ErrorDisplay 
        message="No hay equipos para mostrar. Por favor, vuelva a ejecutar la consulta."
        onReturn={handleReturn}
      />
    );
  }

  if (!cliente?.plantillaType) {
    return (
      <ErrorDisplay 
        message="Para ver las plantillas, primero debe configurar el tipo de plantilla en la lista de clientes"
        onReturn={handleReturn}
      />
    );
  }

  return (
    <div className='cal_pdf_container'>
      <div className='cal_pdf_header'>
        <div className="cal_pdf_toolbar">
          <div className="cal_pdf_left_section">
            <Link to={`/dashboard/perfilcliente/${props.match.params.clienteId}/cronocalperfil`}>
              <MyButton tip="Volver">
                <FaArrowCircleLeft color="#03178C" className="cal_pdf_back_icon"/>
              </MyButton>
            </Link>
          </div>
          <div className="cal_pdf_right_section">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setVerPDF(!verPDF)}
              className="cal_pdf_view_btn"
            >
              {verPDF ? 'Ocultar PDF' : 'Ver PDF'}
            </Button>
          </div>
        </div>
      </div>

      <div className='cal_pdf_content'>
        {verPDF && (
          <PDFViewer style={{ width: "100%", height: "90vh" }}>
            <CronogramaCalDocuPDF equipos={equiposcal} cliente={cliente} />
          </PDFViewer>
        )}
      </div>
    </div>
  );
}

CronogramaCalPDF.propTypes = {
  getCliente: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.data,
  datainfra: state.datainfra
});

export default connect(mapStateToProps, { getCliente })(CronogramaCalPDF);