import React, { useState, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './PlantillaConfig.css';

// Material UI Components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

// Custom Components
import MyButton from '../../../utils/MyButton';

// Icons
import { FaArrowLeft, FaPlus, FaCheck, FaCheckCircle, FaCog } from "react-icons/fa";

// Actions
import { 
  clearErrors, 
  subirPlantilla,
  getClientes 
} from '../../../redux/actions/dataactions';

const PlantillaConfig = ({ 
  clearErrors, 
  subirPlantilla,
  getClientes,
  UI: { loading, errors, aprobado },
  user,
  clienteId 
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    primaryColor: '#000000',
    secondaryColor: '#000000',
    userType: ''
  });
  const [logo, setLogo] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
    clearErrors();
  };

  const handleClose = () => {
    if(aprobado) {
      getClientes();
      setOpen(false);
    }
    setOpen(false);
    clearErrors();
    setFormData({
      primaryColor: '#000000',
      secondaryColor: '#000000',
      plantillaType: ''
    });
    setLogo(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validTypes.includes(file.type)) {
      return false;
    }

    if (file.size > maxSize) {
      return false;
    }

    return true;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const file = e.dataTransfer.files[0];
    if (file && validateFile(file)) {
      setLogo(file);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file && validateFile(file)) {
      setLogo(file);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (!logo || !formData.plantillaType || !formData.primaryColor || !formData.secondaryColor) {
      return;
    }

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('logo', logo);
    formDataToSubmit.append('primaryColor', formData.primaryColor);
    formDataToSubmit.append('secondaryColor', formData.secondaryColor);
    formDataToSubmit.append('plantillaType', formData.plantillaType);
    
    subirPlantilla(formDataToSubmit, clienteId, user.credentials.handle);
  };

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  return (
    <Fragment>
      <MyButton tip="Configurar Plantilla" onClick={handleOpen}>
        <FaCog color="#03178C" className="icon-config"/>
      </MyButton>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {aprobado ? '¡Configuración Exitosa!' : 'Configurar Plantilla'}
        </DialogTitle>

        {aprobado ? (
          <DialogContent>
            <div className='div-aprobado'>
              <div className='div-icono-aprobado'>
                <FaCheck color="#ffffff" className='icono-aprobado'/>
              </div>
              <h2 className='text-aprobado'>¡Configuración guardada correctamente!</h2>
            </div>
          </DialogContent>
        ) : (
          <DialogContent>
            <form className="config-form">
              <div className="form-group">
                <label>Color Primario</label>
                <div className="color-input-container">
                  <input
                    type="color"
                    name="primaryColor"
                    value={formData.primaryColor}
                    onChange={handleChange}
                  />
                  <div 
                    className="color-preview"
                    style={{ backgroundColor: formData.primaryColor }}
                  />
                </div>
                {errors?.primaryColor && (
                  <p className="error-message">{errors.primaryColor}</p>
                )}
              </div>

              <div className="form-group">
                <label>Color Secundario</label>
                <div className="color-input-container">
                  <input
                    type="color"
                    name="secondaryColor"
                    value={formData.secondaryColor}
                    onChange={handleChange}
                  />
                  <div 
                    className="color-preview"
                    style={{ backgroundColor: formData.secondaryColor }}
                  />
                </div>
                {errors?.secondaryColor && (
                  <p className="error-message">{errors.secondaryColor}</p>
                )}
              </div>

              <div className="form-group">
                <label>Tipo de Plantilla</label>
                <select
                  name="plantillaType"
                  value={formData.plantillaType}
                  onChange={handleChange}
                  className="select-input"
                >
                  <option value="">Seleccionar tipo</option>
                  <option value="prestador">Institucional</option>
                  <option value="empresa">Empresarial</option>
                </select>
                {errors?.plantillaType && (
                  <p className="error-message">{errors.plantillaType}</p>
                )}
              </div>

              <div className="form-group">
                <label>Logo</label>
                <div
                  className={`dropzone ${isDragging ? 'dragging' : ''}`}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <div className="dropzone-content">
  <svg className="upload-icon" viewBox="0 0 48 48">
    <path
      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
    />
  </svg>
  <div className="upload-text">
    <button 
      type="button"
      onClick={handleClickUpload}
      className="upload-button"
    >
      Subir un archivo
    </button>
    <input
      ref={fileInputRef}
      type="file"
      onChange={handleFileSelect}
      accept="image/jpeg,image/png,image/svg+xml"
      style={{ display: 'none' }}
    />
    <p>o arrastra y suelta</p>
  </div>
  <p className="file-info">PNG, JPG, SVG hasta 5MB</p>
  {logo && (
    <p className="selected-file">
      Archivo seleccionado: {logo.name}
    </p>
  )}
</div>
                </div>
                {errors?.logo && (
                  <p className="error-message">{errors.logo}</p>
                )}
              </div>

              {errors?.general && (
                <p className="error-message general-error">{errors.general}</p>
              )}
            </form>
          </DialogContent>
        )}

        <DialogActions>
          <MyButton tip="Cerrar" onClick={handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
          </MyButton>

          {loading ? (
            <CircularProgress size={30} color="primary" className="progress" />
          ) : (
            aprobado ? (
              <MyButton tip="Continuar" onClick={handleClose}>
                <FaCheckCircle color="#20B90C" className="icon-formulario" />
              </MyButton>
            ) : (
              <MyButton tip="Guardar Configuración" onClick={handleSubmit}>
                <FaPlus color="#03178C" className="icon-formulario" />
              </MyButton>
            )
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

PlantillaConfig.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  subirPlantilla: PropTypes.func.isRequired,
  getClientes: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  clienteId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  user: state.user
});

export default connect(
  mapStateToProps, 
  { clearErrors, subirPlantilla, getClientes }
)(PlantillaConfig);