import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getListaCal, getCliente } from '../../../redux/actions/dataactions';
import { PDFViewer } from '@react-pdf/renderer';
import './CronogramaCalPDFAsis.css';
import CronogramaCalDocuPDF from '../Equipos/CronogramaCalDocuPDF';

function CronogramaCalPDFAsis(props) {
    const [verPDF, setVerPDF] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (props.match?.params?.clienteId) {
            props.getListaCal(props.match.params.clienteId);
            props.getCliente(props.match.params.clienteId);
        }
    }, [props.match?.params?.clienteId]);

    const handleOpenPDF = () => {
        setVerPDF(!verPDF);
    };

    const handleGoBack = () => {
        history.push('/dashboard/cronogramacalasistencial');
    };

    const { equiposcal, cliente, loading } = props.data;

    const renderContent = () => {
        if (loading) {
            return (
                <div className="inventario-loading-message">
                    <p>Cargando datos...</p>
                </div>
            );
        }

        if (!cliente?.plantillaType) {
            return (
                <div className="inventario-error-message">
                    <p>Para ver las plantillas, primero debe configurar el tipo de plantilla en la lista de clientes</p>
                </div>
            );
        }

        if (!equiposcal) {
            return (
                <div className="inventario-error-message">
                    <p>No se encontraron equipos con calibraciones para mostrar</p>
                </div>
            );
        }

        if (verPDF && equiposcal && cliente) {
            return (
                <PDFViewer className="pdf-viewer">
                    <CronogramaCalDocuPDF 
                        equipos={equiposcal} 
                        cliente={cliente}
                    />
                </PDFViewer>
            );
        }

        return null;
    };

    return (
        <div className='div-inventaripdfgeneral'>
            <div className='div-inventaripdftitulo'>
                <div className="div_boton">
                    <div className="back-button-container">
                        <MyButton tip="Volver" onClick={handleGoBack}>
                            <FaArrowCircleLeft className="icon-eliminarcliente"/>
                        </MyButton>
                    </div>
                    
                    {equiposcal && cliente?.plantillaType && (
                        <div className="button-group-container">
                            <ButtonGroup 
                                variant="text" 
                                aria-label="text button group" 
                                color='primary'
                            >
                                <Button 
                                    onClick={handleOpenPDF}
                                    className="pdf-button"
                                >
                                    {verPDF ? "Ocultar PDF" : "Ver PDF"}
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}
                </div>
            </div>

            <div className='div-inventariopdfcontent'>
                {renderContent()}
            </div>
        </div>
    );
}

CronogramaCalPDFAsis.propTypes = {
    getListaCal: PropTypes.func.isRequired,
    getCliente: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    data: state.data
});

export default connect(mapStateToProps, { getListaCal, getCliente })(CronogramaCalPDFAsis);