import React from 'react';
import PropTypes from 'prop-types'; 
import './Sidebar.css'; 
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';

import {FaThLarge,FaFirstAid,FaFileMedical,FaShippingFast,FaCartPlus,FaNotesMedical,FaShoppingCart,FaUserFriends,FaUserNurse,FaBookReader, FaUsersCog} from "react-icons/fa";

function Sidebar(props) {

   const {
        user:{credentials:{nombre,handle,imageUrl,tipouser}}}= props;
        
        if(tipouser==="Ingeniero"){
            return(
                <div id="sidebar">
             
               
                <div className="sidebar-container">
                    <div className="sidebar-profile">
                    <img src={imageUrl} alt="profile" className="profile-image"/>
                    <h3 className="sidebar-nombre">{nombre}</h3>
                    <h3 className="sidebar-handle">@{handle}</h3>
                    <hr className="sidebar-separador"></hr>
                    </div>
                    <div className="sidebar-list">
                  
                    <div className="sidebar-item">
                            <Link to="/dashboard" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaThLarge color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mi Dashboard</p>
                                </div>                                       
                            </Link>
    
                        </div>
                     
                    <div className="sidebar-item">
                            <Link to="/dashboard/clientes" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaNotesMedical color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Prestadores</p>
                                </div>                                       
                            </Link>
                        </div>
                        <div className="sidebar-item">
                            <Link to="/dashboard/misrepuestos" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaUsersCog color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mis Repuestos</p>
                                </div>                                       
                            </Link>
    
                        </div>
                        <div className="sidebar-item">
                            <Link to="/dashboard/homemarket" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaShoppingCart color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Marketplace</p>
                                </div>                                       
                            </Link>
                        </div>
                    
                   
                    
                      
                        <div className="sidebar-item">
                            <Link to="/dashboard/miperfil" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaUserNurse color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mi Perfil</p>
                                </div>                                       
                            </Link>
                        </div>
                       
                    </div>
                </div>
                
             
            </div>
            )
        }
        else if(tipouser==="Empresa"){
            return(
                <div id="sidebar">
          
               
                <div className="sidebar-container">
                    <div className="sidebar-profile">
                    <img src={imageUrl} alt="profile" className="profile-image"/>
                    <h3 className="sidebar-nombre">{nombre}</h3>
                    <h3 className="sidebar-handle">@{handle}</h3>
                    <hr className="sidebar-separador"></hr>
                    </div>
                    <div className="sidebar-list">
                  
                    <div className="sidebar-item">
                            <Link to="/dashboard" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaThLarge color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mi Dashboard</p>
                                </div>                                       
                            </Link>
    
                        </div>
                    
                        
                     
                                     
                        <div className="sidebar-item">
                            <Link to="/dashboard/homemarketempresa" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaShoppingCart color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Ventas</p>
                                </div>                                       
                            </Link>
                        </div>
                    
                      
                    
                     
                       
                        <div className="sidebar-item">
                            <Link to="/dashboard/miperfil" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaUserNurse color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mi Perfil</p>
                                </div>                                       
                            </Link>
                        </div>
                       
                    </div>
                </div>
                
             
            </div>
            )
        }
        else if(tipouser==="Compras"){
            return(
                <div id="sidebar">
          
               
                <div className="sidebar-container">
                    <div className="sidebar-profile">
                    <img src={imageUrl} alt="profile" className="profile-image"/>
                    <h3 className="sidebar-nombre">{nombre}</h3>
                    <h3 className="sidebar-handle">@{handle}</h3>
                    <hr className="sidebar-separador"></hr>
                    </div>
                    <div className="sidebar-list">
                  
                    <div className="sidebar-item">
                            <Link to="/dashboard" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaThLarge color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mi Dashboard</p>
                                </div>                                       
                            </Link>
    
                        </div>


                        <div className="sidebar-item">
                            <Link to="/dashboard/compras/solicitudes" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaThLarge color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Solicitudes</p>
                                </div>                                       
                            </Link>
    
                        </div>
                

                        <div className="sidebar-item">
                            <Link to="/dashboard/homemarket" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaShoppingCart color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Marketplace</p>
                                </div>                                       
                            </Link>
                        </div> 
                    
                        
                     
                                     
                      
                    
                        <div className="sidebar-item">
                            <Link to="/dashboard/noticias" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaUserFriends color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Noticias</p>
                                </div>                                       
                            </Link>
                        </div>
                    
                     
                       
                        <div className="sidebar-item">
                            <Link to="/dashboard/miperfil" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaUserNurse color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mi Perfil</p>
                                </div>                                       
                            </Link>
                        </div>
                       
                    </div>
                </div>
                
             
            </div>
            )
        }
        else if(tipouser==="Asistencial"){
            return(
                <div id="sidebar">
          
               
                <div className="sidebar-container">
                    <div className="sidebar-profile">
                    <img src={imageUrl} alt="profile" className="profile-image"/>
                    <h3 className="sidebar-nombre">{nombre}</h3>
                    <h3 className="sidebar-handle">@{handle}</h3>
                    <hr className="sidebar-separador"></hr>
                    </div>
                    <div className="sidebar-list">
                  
                    <div className="sidebar-item">
                            <Link to="/dashboard" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaThLarge color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mi Dashboard</p>
                                </div>                                       
                            </Link>
    
                        </div>
                    
                        
                     
                                     
                        <div className="sidebar-item">
                            <Link to="/dashboard/equiposasistencial" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaFirstAid color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Equipos Biomedicos</p>
                                </div>                                       
                            </Link>
                        </div>

                        <div className="sidebar-item">
                            <Link to="/dashboard/solicitudesasistencial" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaFileMedical color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Compras</p>
                                </div>                                       
                            </Link>
                        </div>

                        <div className="sidebar-item">
                            <Link to="/dashboard/infrasasistencial" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaFirstAid color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Infraestructura</p>
                                </div>                                       
                            </Link>
                        </div>
                    
                     
                       
                        <div className="sidebar-item">
                            <Link to="/dashboard/miperfil" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaUserNurse color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mi Perfil</p>
                                </div>                                       
                            </Link>
                        </div>
                       
                    </div>
                </div>
                
             
            </div>
            )
        }
        else if(tipouser==="Tecnico"){
            return(
                <div id="sidebar">
             
               
                <div className="sidebar-container">
                    <div className="sidebar-profile">
                    <img src={imageUrl} alt="profile" className="profile-image"/>
                    <h3 className="sidebar-nombre">{nombre}</h3>
                    <h3 className="sidebar-handle">@{handle}</h3>
                    <hr className="sidebar-separador"></hr>
                    </div>
                    <div className="sidebar-list">
                  
                    <div className="sidebar-item">
                            <Link to="/dashboard" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaThLarge color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mi Dashboard</p>
                                </div>                                       
                            </Link>
    
                        </div>
                     
                   

                        <div className="sidebar-item">
                            <Link to="/dashboard/clientes/tecnico" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaNotesMedical color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mis Prestadores</p>
                                </div>                                       
                            </Link>
                        </div>
                     
                      
                    
                     
                    
                      
                       
                        <div className="sidebar-item">
                            <Link to="/dashboard/miperfil" className="sidebar-link">
                                <div className="sidebar-linkdiv">
                                <FaUserNurse color="#0468BF"className="sidebar-icon"/>
                               <p className="sidebar-text">Mi Perfil</p>
                                </div>                                       
                            </Link>
                        </div>
                       
                    </div>
                </div>
                
             
            </div>
            )
        }

    

}
   


const mapStateToProps = (state)=>({
    user:state.user
})


Sidebar.propTypes = { 
    user: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(Sidebar)



