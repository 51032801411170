import React, { useEffect,useState } from 'react';
import './PerfilEquipo.css'; 
import MyButton from '../../../utils/MyButton';
import { FaFileMedical,FaArrowCircleLeft,FaCogs,FaFilePdf, FaPhoneSquareAlt, FaRegCalendarCheck, FaRocket, FaSortDown, FaSortUp, FaThermometerHalf, FaWrench} from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';



import {getEquipo} from '../../../redux/actions/dataactions';
import ProgramarMto from './ProgramarMto';
import ListaMtoProg from './ListaMtoProg';
import ProgramarCalibracion from './ProgramarCalibracion';
import MtoCorrectivo from './MtoCorrectivo';
import EstadoEquipo from './EstadoEquipo';
import DetallesEquipo from './DetallesEquipo';
import  SubirImgEquipo from './SubirImgEquipo';

import HistorialMtoPrev  from './HistorialMtoPrev';
import HistorialCorrectivos  from './HistorialCorrectivos';
import ListaCalProg  from './ListaCalProg';
import ListaCalibraciones  from './ListaCalibraciones';
import HistorialSolicitudCorrectivos from './HistorialSolicitudCorrectivos';
import BorrarEquipo from './BorrarEquipo';
import { Button } from '@material-ui/core';
import RecomendacionesGemini from './RecomendacionesGemini';
import CrearCasoTecno from '../Tecnovigilancia/crearCasoTecno';
import TablaTecno from '../Tecnovigilancia/TablaTecno';
import AdjuntarDoc from './AdjuntarDoc';
import SolicitudCorrectivo from './SolicitudCorrectivo';

function PerfilEquipo({ getEquipo, data, match }) {
  const history = useHistory();
  const [accion, setAccion] = useState(null);
  useEffect(() => {
    console.log(match.params.equipoId);
    getEquipo(match.params.equipoId);
  }, [getEquipo, match.params.equipoId]);

  const { equipo, loading } = data;
  const [perfilInfoVisible, setPerfilInfoVisible] = useState(false); // Estado para controlar la visibilidad

  const [mostrarComponente, setMostrarComponente] = useState(false); // Estado para controlar la visibilidad del componente

  const toggleAccion = (nuevaAccion) => {
    if (nuevaAccion === accion && mostrarComponente) {
      // Si se hace clic en el botón que ya está seleccionado y el componente está visible, ocultarlo
      setAccion("");
      setMostrarComponente(false);
    } else {
      setAccion(nuevaAccion);
      setMostrarComponente(true);
    }
  };
      

        let cargarEquipo= !loading && equipo.credentials ? (
            <div className="equipment-card">
                <div className="equipment-card__content">
                  
                  <div className='equipment-img__container'>

                  
                <div className="div-imagencliente">
                <img
                src={
                  equipo.credentials.imagenurl === undefined || equipo.credentials.imagenurl === "undefined"
                    ? 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media'
                    : equipo.credentials.imagenurl
                }
                alt="equipo"
                className="img-cliente"
              />
              <SubirImgEquipo equipoId={match.params.equipoId}></SubirImgEquipo>

                </div>

                </div>
    
                <div className="equipment-card__info">
                <div className="div-titulocliente">
           
                <h3 className="text-titulo-cliente">{equipo.credentials.nombre}</h3>
                <p className="perfilequipo-text">{equipo.credentials.estado}</p>
                <p className="perfilequipo-text">{equipo.credentials.idinventario}</p>
                </div>
                

                <div className="div-equipo-info">
                <div className="div-perfil-info">
                <hr className="clientes_separator"></hr>  
                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Marca: </h5>    
                <p className="perfilequipo-text">{equipo.credentials.marca}</p>
                </div>
                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Modelo: </h5>    
                <p className="perfilequipo-text">{equipo.credentials.modelo}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Serie </h5>    
                <p className="perfilequipo-text">{equipo.credentials.serie}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Registro Invima </h5>    
                <p className="perfilequipo-text">{equipo.credentials.registroinvima}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Clase Aislamiento</h5>    
                <p className="perfilequipo-text">{equipo.credentials.clase}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Ubicación</h5>    
                <p className="perfilequipo-text">{equipo.credentials.ubicacion}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Servicio</h5>    
                <p className="perfilequipo-text">{equipo.credentials.servicio}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Equipo</h5>    
                <p className="perfilequipo-text">{equipo.credentials.movilfijo}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Tipo </h5>    
                <p className="perfilequipo-text">{equipo.credentials.tipo}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Clasificación del Riesgo</h5>    
                <p className="perfilequipo-text">{equipo.credentials.riesgo}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Clasificación Biomédica </h5>    
                <p className="perfilequipo-text">{equipo.credentials.clasbiomedica}</p>
                </div>
                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Requiere Calibración </h5>    
                <p className="perfilequipo-text">{equipo.credentials.reqcalibracion=="true"?("Si"):("No")}</p>
                </div>

                 </div>

          
                 <div className="div-perfil-info" style={{ display: perfilInfoVisible ? 'flex' : 'none' }}>
                <hr className="clientes_separator"></hr>  
                
                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Forma de Adquisición </h5>    
                <p className="perfilequipo-text">{equipo.credentials.adquisicion}</p>
                </div>     

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Costo </h5>    
                <p className="perfilequipo-text">${equipo.credentials.costo}</p>
                </div>        

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Vida Util </h5>    
                <p className="perfilequipo-text">{equipo.credentials.vidautil} años</p>
                </div>        

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Fecha de Fabricación </h5>    
                <p className="perfilequipo-text">{equipo.credentials.fabricacion}</p>
                </div>   
                
                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Inicio de Operación</h5>    
                <p className="perfilequipo-text">{equipo.credentials.operacion}</p>
                </div>   

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Proveedor</h5>    
                <p className="perfilequipo-text">{equipo.credentials.proveedor}</p>
                </div>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">E-mail</h5>    
                <p className="perfilequipo-text">{equipo.credentials.proveedoremail}</p>
                </div>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Teléfono</h5>    
                <p className="perfilequipo-text">{equipo.credentials.proveedortel}</p>
                </div>  

                </div>


                <div className="div-perfil-info" style={{ display: perfilInfoVisible ? 'flex' : 'none' }}>
                <hr className="clientes_separator"></hr>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Tipo de Tecnología</h5>    
                <p className="perfilequipo-text">{equipo.credentials.tipotec}</p>
                </div>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Fuente de Alimentación</h5>    
                <p className="perfilequipo-text">{equipo.credentials.fuente}</p>
                </div> 

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Link del Manual</h5>    
                <p className="perfilequipo-text">{!equipo.credentials.urlmanual?<a>Sin Manual Adjunto</a>:<a href={equipo.credentials.urlmanual} target="_blank" rel="noopener noreferrer">Link del Manual</a>}</p>
                </div> 

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Link de Factura</h5>    
                <p className="perfilequipo-text">{!equipo.credentials.urlfactura?<a>Sin Factura Adjunta</a>:<a href={equipo.credentials.urlfactura} target="_blank" rel="noopener noreferrer">Link de la Factura</a>}</p>
                </div> 

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Tensión Min</h5>    
                <p className="perfilequipo-text">{equipo.credentials.tensionmin} V</p>
                </div>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Tensión Max</h5>    
                <p className="perfilequipo-text">{equipo.credentials.tensionmax} V</p>
                </div> 

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Corriente Min</h5>    
                <p className="perfilequipo-text">{equipo.credentials.corrientemin} A</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Corriente Max</h5>    
                <p className="perfilequipo-text">{equipo.credentials.corrientemax} A</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Potencia</h5>    
                <p className="perfilequipo-text">{equipo.credentials.potencia} W</p>
                </div>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Temperatura</h5>    
                <p className="perfilequipo-text">{equipo.credentials.temperatura} °C</p>
                </div>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Frecuencia</h5>    
                <p className="perfilequipo-text">{equipo.credentials.frecuencia} °C</p>
                </div>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Presión</h5>    
                <p className="perfilequipo-text">{equipo.credentials.presion}</p>
                </div>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Alto</h5>    
                <p className="perfilequipo-text">{equipo.credentials.alto} cm</p>
                </div>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Ancho</h5>    
                <p className="perfilequipo-text">{equipo.credentials.ancho} cm</p>
                </div>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Largo</h5>    
                <p className="perfilequipo-text">{equipo.credentials.largo} cm</p>
                </div>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Peso</h5>    
                <p className="perfilequipo-text">{equipo.credentials.peso} Kg</p>
                </div>  

                </div>


               </div>
                </div>
                </div>

                <div className="equipment-card__actions">
                <RecomendacionesGemini clienteId={match.params.clienteId} equipoId={match.params.equipoId}></RecomendacionesGemini>
                <CrearCasoTecno clienteId={match.params.clienteId} equipoId={match.params.equipoId} />
                <AdjuntarDoc clienteId={match.params.clienteId} equipoId={match.params.equipoId} />
                    <DetallesEquipo clienteId={match.params.clienteId} equipoId={match.params.equipoId}></DetallesEquipo>
                    <ProgramarMto clienteId={match.params.clienteId} equipoId={match.params.equipoId}></ProgramarMto>
                    <ProgramarCalibracion clienteId={match.params.clienteId} equipoId={match.params.equipoId} />
                   
                    <SolicitudCorrectivo  clienteId={match.params.clienteId} equipoId={match.params.equipoId}/>
                    <EstadoEquipo clienteId={match.params.clienteId} equipoId={match.params.equipoId}/>
                    <BorrarEquipo clienteId={match.params.clienteId} equipoId={match.params.equipoId} history={history}/>
                    
                    <Link to={`/dashboard/perfilcliente/equipo/hojadevidapdf/${match.params.clienteId}/${match.params.equipoId}`} className="text-link-inventariopdf">
                    <MyButton tip="Hoja de Vida PDF">
                    <FaFilePdf color="#03178C" className="icon-crearcliente"/>
                    </MyButton>
                    </Link>
                    <MyButton tip="Mostrar Más" onClick={() => setPerfilInfoVisible(!perfilInfoVisible)}>
                        {perfilInfoVisible ? ( // Mostrar un icono cuando perfilInfoVisible es true
                            <FaSortUp color="#03178C" className="icon-crearcliente" />
                        ) : ( // Mostrar otro icono cuando perfilInfoVisible es false
                            <FaSortDown color="#03178C" className="icon-crearcliente" />
                        )}
                    </MyButton> 
                    
                </div>
             
            </div>

            
            )
            
            :(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>);
        const handleVolverClick = () => {
              history.goBack();
        };      

        return (
            <div>
                <div className="titulo_section">
                <div className="titulo_clientes">
                <h1 className="text-clientes">Hoja de Vida del Equipo</h1>
                <hr className="clientes_separator"></hr>            
                
                </div>

                <div className="div_boton">
                  <MyButton tip="Volver" onClick={handleVolverClick}>
                    <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
                  </MyButton>
                </div>
                </div>
            
                {cargarEquipo}
                <div className='equipment-actions-grid'>
        <Button
            variant="contained"
            endIcon={<FaWrench style={{ color: accion === "MtosProg" ? "white" : "#03178c" }} />}
            onClick={() => toggleAccion("MtosProg")}
            style={{
                backgroundColor: accion === "MtosProg" ? "#0588FB" : "initial",
                color: accion === "MtosProg" ? "white" : "#03178c",
                marginRight:accion === "MtosProg" ? "10px" : "10px",
                textTransform: accion === "MtosProg" ? "initial" : "initial"
                
            }}
            >
            {accion === "MtosProg" ? "Mtos Programados" : "Mtos. Programados"}
            </Button>
        <Button
          variant="contained"
          endIcon={<FaRocket  style={{ color: accion === "MtosReal" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("MtosReal")} // Actualizar el estado al hacer clic en "MtosReal"
          style={{
            backgroundColor: accion === "MtosReal" ? "#0588FB" : "initial",
            color: accion === "MtosReal" ? "white" : "#03178c",
            marginRight:accion === "MtosReal" ? "10px" : "10px",
            textTransform: accion === "MtosReal" ? "initial" : "initial"
            
        }}
        >
          {accion === "MtosReal" ? "Mtos. Realizados" : "Mtos. Realizados"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaRegCalendarCheck  style={{ color: accion === "CalProg" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("CalProg")} // Actualizar el estado al hacer clic en "CalProg"
          style={{
            backgroundColor: accion === "CalProg" ? "#0588FB" : "initial",
            color: accion === "CalProg" ? "white" : "#03178c",
            marginRight:accion === "CalProg" ? "10px" : "10px",
            textTransform: accion === "CalProg" ? "initial" : "initial"
            
        }}
        >
          {accion === "CalProg" ? "Cal. Programadas" : "Cal. Programadas"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaThermometerHalf  style={{ color: accion === "CalReal" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("CalReal")}// Actualizar el estado al hacer clic en "CalReal"
          style={{
            backgroundColor: accion === "CalReal" ? "#0588FB" : "initial",
            color: accion === "CalReal" ? "white" : "#03178c",
            marginRight:accion === "CalReal" ? "10px" : "10px",
            textTransform: accion === "CalReal" ? "initial" : "initial"
            
        }}
        >
          {accion === "CalReal" ? "Cal. Realizadas" : "Cal. Realizadas"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaCogs  style={{ color: accion === "MtosCorr" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("MtosCorr")} // Actualizar el estado al hacer clic en "MtosCorr"
          style={{
            backgroundColor: accion === "MtosCorr" ? "#0588FB" : "initial",
            color: accion === "MtosCorr" ? "white" : "#03178c",
            marginRight:accion === "MtosCorr" ? "10px" : "10px",
            textTransform: accion === "MtosCorr" ? "initial" : "initial"
            
        }}
        >
          {accion === "MtosCorr" ? "Mtos. Correctivos" : "Mtos. Correctivos"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaPhoneSquareAlt  style={{ color: accion === "SolCorr" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("SolCorr")} // Actualizar el estado al hacer clic en "SolCorr"
          style={{
            backgroundColor: accion === "SolCorr" ? "#0588FB" : "initial",
            color: accion === "SolCorr" ? "white" : "#03178c",
            marginRight:accion === "SolCorr" ? "10px" : "10px",
            textTransform: accion === "SolCorr" ? "initial" : "initial"
            
        }}
        >
          {accion === "SolCorr" ? "Sol. Correctivos" : "Sol. Correctivos"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaFileMedical style={{ color: accion === "Tecnovigilancia" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("Tecnovigilancia")} // Actualizar el estado al hacer clic en "Tecnovigilancia"
          style={{
            backgroundColor: accion === "Tecnovigilancia" ? "#0588FB" : "initial",
            color: accion === "Tecnovigilancia" ? "white" : "#03178c",
            marginRight: accion === "Tecnovigilancia" ? "10px" : "10px",
            textTransform: accion === "Tecnovigilancia" ? "initial" : "initial"

          }}
        >
          {accion === "Tecnovigilancia" ? "Casos Tecnovigilancia" : "Casos Tecnovigilancia"}
        </Button>
      </div>
                
                
      <div className="acordion-container-equipo">
        {mostrarComponente && (
            <div>

             <div>
             {accion === "MtosProg" ? <div>
             <ListaMtoProg clienteId={match.params.clienteId} equipoId={match.params.equipoId} />
             </div> : null}
             </div>

            <div>
            {accion === "MtosReal" ? <div>
            <HistorialMtoPrev clienteId={match.params.clienteId} equipoId={match.params.equipoId} />
            </div> : null}
            </div>

            <div>
            {accion === "CalProg" ? <div>
                <ListaCalProg clienteId={match.params.clienteId} equipoId={match.params.equipoId} />
            </div> : null}
            </div>

            <div>
              {accion === "CalReal" ? <div>
              <ListaCalibraciones clienteId={match.params.clienteId} equipoId={match.params.equipoId}/>
      
              </div> : null}
            </div>

            <div>
              {accion === "MtosCorr" ? <div>
              <HistorialCorrectivos clienteId={match.params.clienteId} equipoId={match.params.equipoId}/>
       
              </div> : null}
            </div>

            <div>
              {accion === "SolCorr" ? <div>
              <HistorialSolicitudCorrectivos  clienteId={match.params.clienteId} equipoId={match.params.equipoId}/>
        
              </div> : null}
            </div>

            <div>
              {accion === "Tecnovigilancia" ? <div>
                <TablaTecno clienteId={match.params.clienteId} equipoId={match.params.equipoId} />
              </div> : null}
            </div>

           </div>

        )}
       
      

        


       
      </div>

                
              
                 
            </div>
        )
        }
//en propiedades recibe el cliente id 

PerfilEquipo.propTypes = {
  getEquipo: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapActionsToProps = { getEquipo };

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, mapActionsToProps)(PerfilEquipo);