import {POST_CRONOGRAMACAL_AUTO,POST_CRONOGRAMA_AUTO,SET_MTOPREV,EDIT_CLIENTE,SET_SOL_CORR,LOADING_SOL_CORR,POST_SOLICITUD_MTO,POST_EDITPROGCAL,POST_EDITPROGMTO,SET_EQUIPOS_CAL,SET_CALIBRACIONES,SET_PROGCALS,SET_MTOSCORRECTIVOS,POST_MTOCORRECTIVO,SET_MTOSPREV,POST_PCAL,POST_MTOPREVENTIVO,SET_PROGMTOS,POST_PMTO,SET_EQUIPOS,SET_EQUIPO,POST_EQUIPO
    ,SET_CLIENTE,SET_CLIENTES, SET_ERRORS, CLEAR_ERRORS,POST_CLIENTE
    ,POST_CALIBRACION, LOADING_DATA, LOADING_UI,SET_EQUIPOS_MTO, LOADING_MTO, LOADING_CAL,LOADING_INDP,SET_INDICADORES_P, LOADING_DATA_CAL, SET_CALIBRACIONES_TABLA, POST_DOCLEGAL, SET_DOCSLEGAL, EDIT_DOCLEGAL, SET_OK, POST_EQUIPO_IA, LOADING_DATA_DATA, SET_EQUIPOIA, SET_OK_DATA, LOADING_DATA_UI, SET_ERRORS_DATA, CLEAR_EQUIPOS_IA,
    POST_RECOMENDACION_IA,
    SET_REPUESTOS,
    SET_CASOS_TECNO,
    SET_CASO_TECNO,
    POST_RUTA_APRENDIZAJE,
    STOP_LOADING_DATA,
    GET_CURSOS,
    GET_CURSOS_RECOMENDADOS,
    SET_TAREAS_CLIENTE,
    SET_INDICADORES_NEW,
    GET_ANALISIS_OBSOLESCENCIA_PRESTADOR,
    GET_ANALISIS_OBSOLESCENCIA_USUARIO} from '../types';
import axios from 'axios'; 
import { getPerfil } from './useractions';
//Obtener todos los "twits"



export const getClientes =()=>dispatch=>{
    dispatch({type:LOADING_DATA});
    axios.get('/getclientes')
    .then(res=>{
        dispatch({
            type:SET_CLIENTES,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}


export const deleteCliente =(clienteId)=>(dispatch)=>{
    dispatch({type:LOADING_DATA});
    axios
    .delete(`/borrarcliente/${clienteId}`)
    .then(() => {
        dispatch(getClientes());
    })
    .catch((err) => console.log(err));
}

export const deleteEquipo=(clienteId,equipoId,history)=>(dispatch)=>{
    
        dispatch({type:LOADING_DATA});
 
       
        axios
        .delete(`/borrarequiponew/${clienteId}/${equipoId}`)
        .then(() => {
            dispatch(clearErrors());
            history.push(`/dashboard/perfilcliente/${clienteId}/inventarioperfil`);
        })
        .catch((err) => console.log(err));
        
    }
    


export const getprogmtos =(equipoId)=>dispatch=>{
    dispatch({type:LOADING_MTO});
    axios.get(`/getprogmto/${equipoId}`)
    .then(res=>{
        dispatch({
            type:SET_PROGMTOS,
            payload:res.data 
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
};

export const getprogcals =(equipoId)=>dispatch=>{
    dispatch({type:LOADING_CAL});
    axios.get(`/getprogcal/${equipoId}`)
    .then(res=>{
        dispatch({
            type:SET_PROGCALS,
            payload:res.data 
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
};

export const getmtosprev =(equipoId)=>dispatch=>{
    dispatch({type:LOADING_MTO});
    axios.get(`/getmtosprev/${equipoId}`)
    .then(res=>{
        dispatch({
            type:SET_MTOSPREV,
            payload:res.data 
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
};

export const getmtoscorrectivos =(equipoId)=>dispatch=>{
    dispatch({type:LOADING_MTO});
    axios.get(`/getmtoscorrectivos/${equipoId}`)
    .then(res=>{
        dispatch({
            type:SET_MTOSCORRECTIVOS,
            payload:res.data 
        });
        
        dispatch(clearErrors());
        
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
};


export const getCalibraciones =(equipoId)=>dispatch=>{
    dispatch({type:LOADING_CAL});
    axios.get(`/getcalibraciones/${equipoId}`)
    .then(res=>{
        dispatch({
            type:SET_CALIBRACIONES,
            payload:res.data 
        });
        console.log("Obtuve Calibraciones")
        dispatch(clearErrors());
        
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
        console.log("Algo salio Mal en Obtener Calibraciones")
    })
    
};

export const getCalibracionesCliente =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_DATA});
    axios.get(`/getcalibracionescliente/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_CALIBRACIONES,
            payload:res.data 
        });
        console.log("Entre a get calibraciones cliente")
        dispatch(clearErrors());
      
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
};
export const subirReporteCalibracionTabla=(formData,equipoId,clienteId,calibracionId)=> (dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    
    axios.post(`/subirreportecalibracion/${clienteId}/${equipoId}/${calibracionId}`,formData)
    .then(()=>{
       
     dispatch(getCalibracionesCliente(clienteId))
      

    
      //getcalibraciones 
    })
    .catch(err=>console.log(err)); 

   
  };

export const deleteDataCalibracionTabla =(dataCalibracion,equipoid,clienteid)=>(dispatch)=>{
    dispatch({type:LOADING_DATA});
    // /deletedatacal/:clienteid/:calibracionid
     axios
     .delete(`/deletedatacal/${clienteid}/${dataCalibracion.calibracionid}`)
     .then(() => {
        
     dispatch(getCalibracionesCliente(clienteid))
    })
     .catch((err) => console.log(err));
    
    
 }

 export const deleteCalibracion =(dataCalibracion,equipoid,clienteid)=>(dispatch)=>{
    
    // /deletecalibracion/:clienteid/:equipoid/:reportefilename/:calibracionid
     axios
     .delete(`/deletecalibracion/${clienteid}/${equipoid}/${dataCalibracion.reportefilename}/${dataCalibracion.calibracionId}`)
     .then(() => {
         console.log('pdf borrado');
         dispatch(getCalibracionesCliente(clienteid))
     })
     .catch((err) => console.log(err));
 }




export const subirImagenCliente=(formData,clienteId)=> (dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    axios.post(`/subirimagen/${clienteId}`,formData)
    .then(()=>{
      dispatch(getClientes());
    })
    .catch(err=>console.log(err)); 
  };


  export const subirDocLegal=(formData,docId,clienteId)=> (dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    axios.post(`/subirdoclegal/${docId}`,formData)
    .then(()=>{
      dispatch(getDocsLegal(clienteId));
    })
    .catch(err=>console.log(err)); 
  };

  export const subirEquipos=(formData,clienteId)=> (dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    axios.post(`/subirequiposcsv/${clienteId}`,formData)
    .then(()=>{
      dispatch(getCliente(clienteId));
      
      dispatch(getEquipos(clienteId));
      dispatch(getIndicadoresPrestador(clienteId));
    })
    .catch(err=>console.log(err)); 
  };

 
  export const subirEquiposExcel=(formData,clienteId,handleUser)=>(dispatch)=>{
    dispatch({ type: LOADING_UI });
    axios.post(`/subirExcel/${handleUser}/${clienteId}`, formData)
      .then((res) => {
        dispatch({
          type: SET_OK,
          payload: res.data
        });
        console.log("Se ejecutó y ahora está en el getCliente");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
  }

  export const subirDocumento = (formData, clienteId, equipoId, handleUser) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post(`/subirDocumento/${handleUser}/${clienteId}/${equipoId}`, formData)
      .then((res) => {
        dispatch({
          type: SET_OK,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  export const subirPlantilla = (formData, clienteId, handleUser) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post(`/subirPlantilla/${clienteId}`, formData)
      .then((res) => {
        dispatch({
          type: SET_OK,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };
  export const subirImagenPerfil=(formData,handleuser)=> (dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    axios.post(`/subirimagenperfil/${handleuser}`,formData)
    .then(()=>{
      dispatch(getPerfil());
    })
    .catch(err=>console.log(err)); 
  };

  export const subirFirmaCliente=(formData,clienteid)=> (dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    axios.post(`/subirfirmacliente/${clienteid}`,formData)
    .then(()=>{
      dispatch(getClientes());
    })
    .catch(err=>console.log(err)); 
  };

  export const subirFirma=(formData,handleuser)=> (dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    axios.post(`/subirfirma/${handleuser}`,formData)
    .then(()=>{
      dispatch(getPerfil());
    })
    .catch(err=>console.log(err)); 
  };


  

export const subirImagenEquipo=(formData,equipoId)=> (dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    axios.post(`/subirimagenequipo/${equipoId}`,formData)
    .then(()=>{
      dispatch(getEquipo(equipoId));
    })
    .catch(err=>console.log(err)); 
  };


  export const subirImagenEquipoInventario=(formData,equipoId,clienteId)=> (dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    axios.post(`/subirimagenequipo/${equipoId}`,formData)
    .then(()=>{
      dispatch(getEquipos(clienteId));
    })
    .catch(err=>console.log(err)); 
  };

export const subirReporteCalibracion=(formData,equipoId,clienteId,calibracionId)=> (dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    axios.post(`/subirreportecalibracion/${clienteId}/${equipoId}/${calibracionId}`,formData)
    .then(()=>{
      dispatch(getEquipo(equipoId));
      dispatch(getCalibraciones(equipoId)); 
      //getcalibraciones 
    })
    .catch(err=>console.log(err)); 
  };

  



export const subirReporte=(formData,equipoId)=>(dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    axios.post(`/subirreporte/${equipoId}`,formData)
    .then(()=>{
      dispatch(getEquipo(equipoId));
    
    })
    .catch(err=>console.log(err)); 
  };


  
export const getCliente=(clienteId)=>(dispatch)=>{
    
    dispatch({type:LOADING_DATA});

    axios.get(`/getcliente/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_CLIENTE,
            payload:res.data.credentials
        });
        dispatch(clearErrors());
    })
    .catch(err=>{
        dispatch({
            type:SET_ERRORS,
            payload:null
        })
    })

}

export const getClienteTareas=(clienteId)=>(dispatch)=>{
    


    axios.get(`/getcliente/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_CLIENTE,
            payload:res.data.credentials
        });
        dispatch(clearErrors());
    })
    .catch(err=>{
        dispatch({
            type:SET_ERRORS,
            payload:null
        })
    })

}



export const editCliente=(clienteDetails,clienteId)=>(dispatch)=>{
    
    dispatch({type:LOADING_UI});
    axios.post(`/editcliente/${clienteId}`,clienteDetails)
    .then((res)=>{
        dispatch({
            type:EDIT_CLIENTE,
            payload:res.data
        })
        //dispatch get programacion mtos
        dispatch(clearErrors());
        dispatch(getClientes());   
     
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
    
  
    dispatch(clearErrors());
    console.log('sali de edit');
  };


export const eliminarDatosCliente=(clienteId)=>(dispatch)=>{
    axios
    .delete(`/deletecliente/${clienteId}`)
    .then(() => {
        dispatch(getClientes());
    })
    .catch((err) => console.log(err));
}

export const postCliente=(newCliente)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post('/crearcliente',newCliente)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data,
        });
        dispatch(clearErrors());
          
      
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const addTecnico=(newTecnico,clienteId)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/addtecnico/${clienteId}`,newTecnico)
    .then((res)=>{
        dispatch({
            type:POST_CLIENTE,
            payload:res.data
        });
        dispatch(clearErrors());
        dispatch(getClientes());    
      
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const postCronogramaMtoAuto=(data,clienteId)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/cronogramamtoauto/${clienteId}`,data)
    .then((res)=>{
        dispatch({
            type:POST_CRONOGRAMA_AUTO,
            payload:res.data
        });
        dispatch(clearErrors());
        dispatch(getListaMtos(clienteId));    
      
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}
export const postCronogramaCalAuto=(data,clienteId)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/cronogramacalauto/${clienteId}`,data)
    .then((res)=>{
        dispatch({
            type:POST_CRONOGRAMACAL_AUTO,
            payload:res.data
        });
        dispatch(clearErrors());
        dispatch(getListaCal(clienteId));    
      
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const editClienteDetails=(clienteDetails,clienteId)=>(dispatch)=>{
    
    dispatch({type:LOADING_UI});
    axios.post(`/detailscliente/${clienteId}`,clienteDetails)
    .then(()=>{
        //dispatch get programacion mtos
        dispatch(getCliente(clienteId));
     
    })
    .catch(err=>console.log(err)); 
    
  
    dispatch(clearErrors());
    console.log('sali de edit');
  };
 

export const editEquipoDetails=(equipoDetails,equipoId)=>(dispatch)=>{
    
    dispatch({type:LOADING_UI});
    axios.post(`/detailsequipo/${equipoId}`,equipoDetails)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(getEquipo(equipoId));
        
        //dispatch get programacion mtos
        
       
     
    })
    .catch((err)=>{
        dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      })
    }
      ); 
    
  
    
    console.log('sali de edit');
  };
  //editEstado
  export const editEstado=(estadoEquipo,equipoId)=>(dispatch)=>{
    
    dispatch({type:LOADING_UI});
    axios.post(`/cambiarestado/${equipoId}`,estadoEquipo)
    .then(()=>{
        //dispatch get programacion mtos
        dispatch(getEquipo(equipoId));
     
    })
    .catch(err=>console.log(err)); 
    
  
    dispatch(clearErrors());
    console.log('sali de edit');
  };


export const progMto=(newpmto,clienteId,equipoId)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/programarmto/${clienteId}/${equipoId}`,newpmto)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

        dispatch(getprogmtos(equipoId));
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const progCalibracion=(newprogcal,clienteId,equipoId)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/progrcalibracion/${clienteId}/${equipoId}`,newprogcal)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

        //dispatch get programacion mtos
        dispatch(getprogcals(equipoId)); 
    
       
        //dispatch(getprogmtos(equipoId));
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};


export const postSolicitudMtoCorrectivo = (newSolicitud, equipoId) => dispatch => {
  dispatch({type: LOADING_UI});
  axios
      .post(`/solicitarmtocorrectivo/${equipoId}`, newSolicitud)
      .then((res) => {
          dispatch({
              type: POST_SOLICITUD_MTO,
              payload: res.data
          });
          dispatch({
              type: SET_OK,
              payload: {
                  mensaje: 'Solicitud enviada exitosamente'
              }
          });
          dispatch(clearErrors());
          //dispatch(getEquipo(equipoId));
          //dispatch(getSolicitudesCorrectivos(equipoId));
      })
      .catch((err) => {
          dispatch({
              type: SET_ERRORS,
              payload: err.response.data
          });
      });
};

export const subirEvidenciaMtoCorrectivo = (formData, mtoid) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post(`/subirevidenciamtocorrectivo/${mtoid}`, formData)
      .then((res) => {
        dispatch({
          type: SET_OK,
          payload: res.data
        })
        dispatch(clearErrors());
     
      })
      .catch(err => console.log(err));
  };


export const postMtoCorrectivo=(newmtocorr,equipoid,clienteid)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/postmtocorrectivo/${clienteid}/${equipoid}`,newmtocorr)
    .then((res)=>{
        dispatch({
            type:POST_MTOCORRECTIVO,
            payload:res.data
        });
        dispatch(clearErrors());

        //dispatch get programacion mtos
        //dispatch get mtos correctivos
        dispatch(getmtoscorrectivos(equipoid));
        dispatch(getSolicitudesCorrectivos(equipoid));
        dispatch(getEquipo(equipoid));
        //dispatch(getmtospreventivos(equipoId));
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const postMtoCorrectivoConSolicitud=(newmtocorr,equipoid,clienteid,solicitudid)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/postmtocorrectivowithsolicitud/${clienteid}/${equipoid}/${solicitudid}`,newmtocorr)
    .then((res)=>{
        dispatch({
            type:POST_MTOCORRECTIVO,
            payload:res.data
        });
        dispatch(clearErrors());

        //dispatch get programacion mtos
        //dispatch get mtos correctivos
        dispatch(getmtoscorrectivos(equipoid));
        dispatch(getSolicitudesCorrectivos(equipoid));
        dispatch(getEquipo(equipoid));
        //dispatch(getmtospreventivos(equipoId));
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}


export const postMto=(newmtoprev,equipoid,clienteid,handleuser)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/postmtopreven/${clienteid}/${equipoid}/${handleuser}`,newmtoprev)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

        //dispatch get programacion mtos
        
        
        dispatch(getmtosprev(equipoid));
        //dispatch(getmtospreventivos(equipoId));
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const postMtoTabla=(newmtoprev,equipoid,clienteid,progid,handle)=>(dispatch)=>{
    console.log('Equipo Id'+equipoid);
    console.log('Cliente Id'+clienteid);
    console.log('Prog Id'+progid);
    console.log('Handle'+handle);
    dispatch({type:LOADING_UI});
    axios
    .post(`/postmtopreven/${clienteid}/${equipoid}/${handle}/${progid}`,newmtoprev)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

        //dispatch get programacion mtos
        //editprogmto 

        dispatch(editProgMtoTabla({nuevoestado:'Realizado'},progid,clienteid))
        //***** */  
        //history.push(`/dashboard/perfilcliente/${clienteid}/cronomtoperfil`);     
       
        //dispatch(getmtospreventivos(equipoId));
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const postCalibracion=(newcal,clienteid,equipoid,handleuser)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/postcalibracion/${clienteid}/${equipoid}/${handleuser}`,newcal)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

        dispatch(getCalibraciones(equipoid));
        
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

}

export const postCalibracionTabla=(newcal,clienteid,equipoid,progid,handleuser)=>(dispatch)=>{

    dispatch({type:LOADING_UI});
    axios
    .post(`/postcalibracion/${clienteid}/${equipoid}/${handleuser}`,newcal)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        //dispatch(getCalibraciones(equipoid));
        dispatch(clearErrors());
       // dispatch(editProgCalTabla({nuevoestado:'Realizado'},progid,clienteid))
        
        
        
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

}

export const editProgMto=(nuevoestado,progid,equipoid)=>(dispatch)=>{
    axios
    .post(`/editprogmto/${progid}`,nuevoestado)
    .then((res)=>{
        dispatch({
            type:POST_EDITPROGMTO,
            payload:res.data
        });
        dispatch(clearErrors());

              
        dispatch(getprogmtos(equipoid));
   
        //dispatch(getmtospreventivos(equipoId));
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const editProgMtoTabla=(nuevoestado,progid,clienteid)=>(dispatch)=>{
    axios
    .post(`/editprogmto/${progid}`,nuevoestado)
    .then((res)=>{
        dispatch({
            type:POST_EDITPROGMTO,
            payload:res.data
        });
        dispatch(clearErrors());

        //dispatch get programacion mtos
        
        
        

        //dispatch(getmtospreventivos(equipoId));
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}


export const deleteProgMto =(clienteid,progid,equipoid,handleuser)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios
    .delete(`/deleteprogmto/${clienteid}/${progid}/${handleuser}`)
    .then((res) => {
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

        //dispatch get programacion mtos
               
        dispatch(getprogmtos(equipoid));
    })
    .catch((err) => console.log(err));
}



export const deleteProgMtoTabla =(clienteid,progid,handle)=>(dispatch)=>{
    axios
    .delete(`/deleteprogmto/${clienteid}/${progid}/${handle}`)
    .then((res) => {
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());
      
       
    })
    .catch((err) => console.log(err));
}

export const editProgCal=(nuevoestado,progid,equipoid)=>(dispatch)=>{
    axios
    .post(`/editprogcal/${progid}`,nuevoestado)
    .then((res)=>{
        dispatch({
            type:POST_EDITPROGCAL,
            payload:res.data
        });
        dispatch(clearErrors());

        //dispatch get programacion mtos
      
        
        dispatch(getprogcals(equipoid));
   
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}
export const editProgCalTabla=(nuevoestado,progid,clienteid)=>(dispatch)=>{
    axios
    .post(`/editprogcal/${progid}`,nuevoestado)
    .then((res)=>{
        dispatch({
            type:POST_EDITPROGCAL,
            payload:res.data
        });
        dispatch(clearErrors());
        //dispatch get programacion mtos
        dispatch(getListaCalFiltrado(clienteid, "01/01/1950", "01/01/2035"))
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}
export const deleteProgCal =(progid,equipoid,clienteid,handleuser)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios
    .delete(`/deleteprogcal/${clienteid}/${progid}/${handleuser}`)
    .then((res) => {
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());
        dispatch(getprogcals(equipoid));
        
      
    })
    .catch((err) => console.log(err));
}

export const deleteProgCalTabla =(progid,handleuser,clienteid)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios
    .delete(`/deleteprogcal/${clienteid}/${progid}/${handleuser}`)
    .then(() => {
        dispatch(clearErrors());
        //dispatch(getEquipo(equipoid));
        
        dispatch(getListaCalFiltrado(clienteid, "01/01/1950", "01/01/2035"));
       
      
    })
    .catch((err) => console.log(err));
}



export const deleteDataCalibracion =(dataCalibracion,equipoid,clienteid)=>(dispatch)=>{
    
    // /deletedatacal/:clienteid/:calibracionid
     axios
     .delete(`/deletedatacal/${clienteid}/${dataCalibracion.calibracionid}`)
     .then(() => {
        dispatch(getEquipo(equipoid));
        dispatch(getCalibraciones(equipoid));
        
     })
     .catch((err) => console.log(err));
 }

 

export const deleteMtoPreventivo =(mtoid,equipoid,clienteid,handleuser)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
   
    axios
    .delete(`/deletemtoprev/${clienteid}/${mtoid}/${handleuser}`)
    .then((res) => {
        dispatch({
            type:SET_OK,
            payload:res.data
        });
      
        dispatch(getmtosprev(equipoid));
    })
    .catch((err) => console.log(err));
}

export const deleteMtoCorrectivo =(mtoid,equipoid)=>(dispatch)=>{
    axios
    .delete(`/deletemtocorrectivo/${mtoid}`)
    .then(() => {
        dispatch(getEquipo(equipoid));
        dispatch(getmtoscorrectivos(equipoid));
    })
    .catch((err) => console.log(err));
}







export const postEquipo=(newEquipo)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post('/crearequipo',newEquipo)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());
        
       
        

        dispatch(getEquipos(newEquipo.idprestador));
        
              
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const postNuevoEquipoIA=(newEquipo)=>dispatch=>{
    dispatch({type:LOADING_DATA_UI});
    axios
    .post('/crearequipo',newEquipo)
    .then((res)=>{
        dispatch({
            type:SET_OK_DATA,
            payload:res.data
        });
        dispatch(clearErrors());
        
            
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS_DATA,
            payload: err.response.data
          });
    });

};

export const clearEquiposIA=()=>dispatch=>{
    dispatch({type:CLEAR_EQUIPOS_IA});
}

export const postEquipoIA=(newEquipo)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post('/consultaequipoia',newEquipo)
    .then((res)=>{
        dispatch({
            type:POST_EQUIPO_IA,
            payload:res.data
        });
        dispatch(clearErrors());
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};


export const getEquipoIA =(equipoId)=>dispatch=>{
    dispatch({type:LOADING_DATA_DATA});
    axios.get(`/getequipoia/${equipoId}`)
    .then(res=>{
        dispatch({
            type:SET_EQUIPOIA,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
}

export const getEquipos =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_DATA});
    axios.get(`/getequipos/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_EQUIPOS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getListaMtos =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_MTO});
    axios.get(`/getlistamtosnew/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_EQUIPOS_MTO,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getListaMtosClienteFiltrado = (clienteId, fechaInicio, fechaFin) => dispatch => {
    
    axios.get(`/getlistamtosfiltradocliente/${clienteId}`, {
      params: {
        fechaInicio: fechaInicio,
        fechaFin: fechaFin
      }
    })
      .then(res => {
        dispatch({
            type:SET_EQUIPOS_MTO,
            payload:res.data
        });
        dispatch(clearErrors());
      }).catch(err => {
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
        
      })
  }
  

export const getListaMtosMes =(clienteId,mes,year,)=>dispatch=>{
    dispatch({type:LOADING_MTO});
    axios.get(`/getlistamtosmes/${clienteId}/mantenimiento/${mes}/${year}`)
    .then(res=>{
        dispatch({
            type:SET_EQUIPOS_MTO,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getListaCal =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_CAL});
    axios.get(`/getlistacal/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_EQUIPOS_CAL,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getListaCalFiltrado = (clienteId, fechaInicio, fechaFin) => dispatch => {
    
    axios.get(`/getlistacalfiltradocliente/${clienteId}`, {
      params: {
        fechaInicio: fechaInicio,
        fechaFin: fechaFin
      }
    })
      .then(res => {
        dispatch({
            type:SET_EQUIPOS_CAL,
            payload:res.data
        });
        dispatch(clearErrors());
      }).catch(err => {
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
        
      })
  }

export const getListaCalMes =(clienteId,mes,year)=>dispatch=>{
    dispatch({type:LOADING_CAL});
    axios.get(`/getlistacalmes/${clienteId}/calibracion/${mes}/${year}`)
    .then(res=>{
        dispatch({
            type:SET_EQUIPOS_CAL,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getIndicadoresPrestador =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_INDP});
    axios.get(`/getindicadoresprestador/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_INDICADORES_P,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}


export const getIndicadoresPrestadorNew =(clienteId)=>dispatch=>{
  dispatch({type:LOADING_INDP});
  axios.get(`/getIndicadoresCliente/${clienteId}`)
  .then(res=>{
      dispatch({
          type:SET_INDICADORES_P,
          payload:res.data
      });
      dispatch(clearErrors());
  }).catch(err=>{
      dispatch({
          type: SET_ERRORS,
          payload:[]
      });
  })
  
}

export const getSolicitudesCorrectivos =(equipoId)=>dispatch=>{
    dispatch({type:LOADING_SOL_CORR});
    axios.get(`/getsolicitudescorrectivo/${equipoId}`)
    .then(res=>{
        dispatch({
            type:SET_SOL_CORR,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getSolicitudesCorrectivosCliente =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_SOL_CORR});
    axios.get(`/getsolicitudesmtocliente/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_SOL_CORR,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}




export const getEquipo =(equipoId)=>dispatch=>{
    dispatch({type:LOADING_DATA});
    axios.get(`/getequipo/${equipoId}`)
    .then(res=>{
        dispatch({
            type:SET_EQUIPO,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
}


export const getMtoPrev =(mtoId)=>dispatch=>{
    dispatch({type:LOADING_DATA});
    console.log('Entre a getMtoPrev')
    axios.get(`/getmtopreventivo/${mtoId}`)
    .then(res=>{
        dispatch({
            type:SET_MTOPREV,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
}

export const getMtoCorr =(mtoId)=>dispatch=>{
    dispatch({type:LOADING_DATA});
    axios.get(`/getmtocorrectivo/${mtoId}`)
    .then(res=>{
        dispatch({
            type:SET_MTOPREV,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
}

export const postDocLegal=(newDoc,clienteId)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/postlegal/${clienteId}`,newDoc)
    .then((res)=>{
        dispatch({
            type:POST_DOCLEGAL,
            payload:res.data
        });
        dispatch(clearErrors());
        dispatch(getDocsLegal(clienteId));    
      
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};


export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };


  export const getDocsLegal =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_DATA});
    axios.get(`/getlegal/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_DOCSLEGAL,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const deleteDocLegal =(docId,clienteId)=>(dispatch)=>{
    dispatch({type:LOADING_DATA});
    axios
    .delete(`/deletedoclegal/${docId}`)
    .then(() => {
        dispatch(getDocsLegal(clienteId));
    })
    .catch((err) => console.log(err));
}

export const editDocLegal=(docDetails,clienteId,docId)=>(dispatch)=>{
    
    dispatch({type:LOADING_UI});
    axios.post(`/editdoclegal/${docId}`,docDetails)
    .then((res)=>{
        dispatch({
            type:EDIT_DOCLEGAL,
            payload:res.data
        })
        dispatch(getDocsLegal(clienteId));  
        dispatch(clearErrors());
        
     
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
    
  
    
  };

  export const ingenieriaGemini = (dataequipo) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post('/ingenieriaGemini', dataequipo)
      .then((res) => {
        dispatch({
          type: POST_RECOMENDACION_IA,
          payload: res.data
        });
        console.log(res.data);
        dispatch(clearErrors());
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      })
  }

  export const asistencialGemini = (dataequipo) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post('/asistencialGemini', dataequipo)
      .then((res) => {
        dispatch({
          type: POST_RECOMENDACION_IA,
          payload: res.data
        });
        dispatch(clearErrors());
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      })
  };

  export const getRepuestos =(userHandle)=>dispatch=>{
    dispatch({type:LOADING_DATA});
    axios.get(`/getRepuestos/${userHandle}`)
    .then(res=>{
        dispatch({
            type:SET_REPUESTOS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const postRepuesto=(newRepuesto,handleUser)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/crearRepuesto/${handleUser}`,newRepuesto,)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());
        
       
        

        dispatch(getRepuestos(handleUser));
        
              
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const subirRepuestosExcel=(formData,handleUser)=>(dispatch)=>{
    dispatch({ type: LOADING_UI });
    axios.post(`/subirExcelRepuestos/${handleUser}`, formData)
      .then((res) => {
        dispatch({
          type: SET_OK,
          payload: res.data
        });
        console.log("Se ejecutó y ahora está en el getRepuestos");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
  }

  export const subirImagenRepuesto=(formData,repuestoId,handleuser)=> (dispatch)=>{
    dispatch({type:LOADING_DATA});
    
    axios.post(`/subirimagenrepuesto/${repuestoId}`,formData)
    .then(()=>{
      dispatch(getRepuestos(handleuser));
    })
    .catch(err=>console.log(err)); 
  };

  export const deleteRepuesto =(repuestoid)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
   
    axios
    .post(`/deleteRepuesto/${repuestoid}`)
    .then((res) => {
        dispatch({
            type:SET_OK,
            payload:res.data
        });
      
        
    })
    .catch((err) => console.log(err));
}

export const editRepuesto=(newRepuesto,repuestoId,handleUser)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/editRepuesto/${repuestoId}`,newRepuesto)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());
        
              
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};


export const createCasoTecno = (dataReporte, clienteId, equipoId) => dispatch => {
    dispatch({ type: LOADING_UI });
    console.log(dataReporte, clienteId, equipoId)
    axios.post(`/crearCasoTecno/${clienteId}/${equipoId}`, dataReporte)
      .then(res => {
        dispatch({
          type: SET_OK,
          payload: res.data
        });
        dispatch(clearErrors());
      }).catch(err => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
        console.log(err.response.data)
      })
  }
  
  export const editCasoTecno = (dataReporte, idReporte) => dispatch => {
    dispatch({ type: LOADING_UI });
    console.log(dataReporte, idReporte)
    axios.post(`/editarCasoTecno/${idReporte}`, dataReporte)
      .then(res => {
        dispatch({
          type: SET_OK,
          payload: res.data
        });
        dispatch(clearErrors());
      }).catch(err => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
        console.log(err.response.data)
      })
  }
  
  export const getCasosTecnoCliente = (clienteId, fechaInicio, fechaFin) => dispatch => {
    dispatch({ type: LOADING_UI });
    axios.get(`/getCasosTecnoTotal/${clienteId}`, {
      params: {
        fechaInicio: fechaInicio,
        fechaFin: fechaFin
      }
    })
      .then(res => {
        dispatch({
          type: SET_CASOS_TECNO,
          payload: res.data
        });
        dispatch(clearErrors());
      }).catch(err => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
        console.log(err.response.data)
      })
  }
  
  export const getCasosTecnoEquipo = (equipoId) => dispatch => {
    dispatch({ type: LOADING_UI });
    axios.get(`/getCasosTecnoEquipo/${equipoId}`)
      .then(res => {
        dispatch({
          type: SET_CASOS_TECNO,
          payload: res.data
        });
        dispatch(clearErrors());
      }).catch(err => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
        console.log(err.response.data)
      })
  }
  
  export const getCasoTecno = (docId, clienteId) => dispatch => {
    dispatch({ type: LOADING_UI });
    axios.get(`/getCasoTecno/${clienteId}/${docId}`)
      .then(res => {
        dispatch({
          type: SET_CASO_TECNO,
          payload: res.data
        });
        dispatch(clearErrors());
      }).catch(err => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
        console.log(err.response.data)
      })
  }
  
  
  
  
  export const deleteCasoTecno = (docId, equipoId) => dispatch => {
    dispatch({ type: LOADING_UI });
    axios.delete(`/eliminarCasoTecno/${docId}`)
      .then((res) => {
        dispatch({
          type: SET_OK,
          payload: res.data
        });
        dispatch(getCasosTecnoEquipo(equipoId))
      })
      .catch((err) => {
        console.log('Error al eliminar el usuario');
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        })
      });
  }
  
  
export const getTotalCursos = () => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.get('/getcursos')
      .then((res) => {
        dispatch({
          type: GET_CURSOS,
          payload: res.data
        })
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        })
        console.log("Ocurrió un error")
      })
  }
  
  export const getCursosRecomendados = (userHandle) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.get(`/getCursosRecomendados/${userHandle}`)
      .then((res) => {
        dispatch({
          type: GET_CURSOS_RECOMENDADOS,
          payload: res.data
        })
        dispatch(clearErrors());
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        })
        console.log("Ocurrió un error")
      })
  }
  
  export const postRutaDeAprendizaje = (preferencias) => (dispatch) => {
    dispatch({ type: LOADING_DATA_UI })
    axios.post(`/rutaDeAprendizaje`, preferencias)
      .then((res) => {
        dispatch({
          type: POST_RUTA_APRENDIZAJE,
          payload: res.data
        })
        dispatch({ type: STOP_LOADING_DATA })
        dispatch(clearErrors());
        console.log(res.data)
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS_DATA,
          payload: err.response.data
        })
        console.log("Ocurrió un error")
      })
  }

  export const guardarRutaCursos=(nuevaruta)=>(dispatch)=>{
    dispatch({type:LOADING_DATA_UI})

    axios.post(`/addCursosRecomendados`,nuevaruta)
    .then((res) => {
      dispatch({
        type:  SET_OK_DATA,
        payload: res.data
      });
      
    })
    .catch((err) => {
      console.log('Error al guardar ruta');
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      })
    });
    
  }

  export const getTareasClienteFiltrado= (clienteId, fechaInicio, fechaFin) => dispatch => {
    
    axios.get(`/gettareascliente/${clienteId}`, {
      params: {
        fechaInicio: fechaInicio,
        fechaFin: fechaFin
      }
    })
      .then(res => {
        dispatch({
          type: SET_TAREAS_CLIENTE,
          payload: res.data
        });
        dispatch(clearErrors());
      }).catch(err => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
        console.log(err.response.data)
      })
  }

  export const postTareaCliente = (dataTarea, clienteId) => dispatch => {
    dispatch({ type: LOADING_UI });
  
    axios.post(`/creartareacliente/${clienteId}`, dataTarea)
      .then(res => {
        dispatch({
          type: SET_OK,
          payload: res.data
        });
        dispatch(clearErrors());
      }).catch(err => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
        console.log(err.response.data)
      })
  }
  
  export const postAprobarTarea=(tareaid)=>(dispatch)=>{
    dispatch({type:LOADING_UI})

    axios.post(`/aprobartarea/${tareaid}`)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });

    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const deleteTarea = (tareaId) => dispatch => {
  dispatch({ type: LOADING_UI });
  axios.delete(`/deletetarea/${tareaId}`)
    .then((res) => {
      dispatch({
        type: SET_OK,
        payload: res.data
      });
     
    })
    .catch((err) => {
      console.log('Error al eliminar esta tarea');
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      })
    });
}

export const editTarea=(newTarea, tareaId)=>dispatch=>{
  dispatch({type:LOADING_UI});
  axios
  .post(`/edittareacliente/${tareaId}`,newTarea,)
  .then((res)=>{
      dispatch({
          type:SET_OK,
          payload:res.data
      });
      dispatch(clearErrors());      

    
      
            
  })
  .catch((err)=>{
      dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
  });

};

export const getIndicadoresNew= (handleuser) => dispatch => {
    
  axios.get(`/getIndicadores/${handleuser}`)
    .then(res => {
      dispatch({
        type: SET_INDICADORES_NEW,
        payload: res.data
      });
      dispatch(clearErrors());
    }).catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
      console.log(err.response.data)
    })
}

export const getAnalisisObsolescenciaUsuario = (userHandle, En_Mto, De_Baja, Corr, Sol, Mas_viejos, vida, tecno, umbral) => (dispatch) => {
  dispatch({ type: LOADING_DATA_UI })
  axios.get(`/datosAnalisisDeObsolescenciaUsuario/${userHandle}`, {
    params: {
      En_Mantenimiento: En_Mto,
      De_Baja: De_Baja,
      Correctivos: Corr,
      Solicitudes: Sol,
      Mas_viejos: Mas_viejos,
      vida: vida,
      casos_tecnovigilancia: tecno,
      umbral: umbral,
    }
  })
    .then((res) => {
      //console.log(res.data)
      dispatch({
        type: GET_ANALISIS_OBSOLESCENCIA_USUARIO,
        payload: res.data
      })
      dispatch(clearErrors());
      dispatch({ type: STOP_LOADING_DATA })
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS_DATA,
        payload: err.response.data
      })
      console.log("Ocurrió un error")
    })
}

export const getAnalisisObsolescenciaPrestador = (clienteId, En_Mto, De_Baja, Corr, Sol, Mas_viejos, vida, tecno, umbral) => (dispatch) => {
  dispatch({ type: LOADING_DATA_UI })
  axios.get(`/datosAnalisisDeObsolescenciaPrestador/${clienteId}`, {
    params: {
      En_Mantenimiento: En_Mto,
      De_Baja: De_Baja,
      Correctivos: Corr,
      Solicitudes: Sol,
      Mas_viejos: Mas_viejos,
      vida: vida,
      casos_tecnovigilancia: tecno,
      umbral: umbral,
    }
  })
    .then((res) => {
      //console.log(res.data)
      dispatch({
        type: GET_ANALISIS_OBSOLESCENCIA_PRESTADOR,
        payload: res.data
      })
      dispatch(clearErrors());
      dispatch({ type: STOP_LOADING_DATA })
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS_DATA,
        payload: err.response.data
      })
      console.log("Ocurrió un error")
    })
}

export const postAnalisisPrestador = (data) => (dispatch) => {
  dispatch({ type: LOADING_DATA_UI })
  axios.post(`/postAnalisisDeObsolescencia`, data)
    .then((res) => {
      dispatch(clearErrors());
      //console.log(res.data)

      dispatch({
        type: SET_OK,
        payload: res.data
      });
      dispatch({ type: STOP_LOADING_DATA })

      console.log("Se ejecutó y ahora está en el getCliente");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
}