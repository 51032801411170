import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import alphalogo from '../../../utils/alphahorizontal.png';

const createStyles = (primaryColor = '#03178c', secondaryColor = '#0066cc') => {
  const colors = {
    primary: primaryColor,
    secondary: secondaryColor,
    gray: {
      50: '#F2F2F2',
      100: '#F1F4F8',
      200: '#E3E8EF',
      300: '#D2DDEB',
      800: '#3B506C',
    }
  };

  return StyleSheet.create({
    page: {
      backgroundColor: '#FFFFFF',
      padding: 20,
      paddingBottom: 40,
    },
    header: {
      backgroundColor: '#FFFFFF',
      padding: '12',
      borderRadius: 8,
      marginBottom: 20,
      border: `1px solid ${colors.secondary}`,
      boxShadow: '0 2px 4px rgba(27, 58, 107, 0.1)',
    },
    headerContent: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 15,
    },
    headerLeft: {
      width: '30%',
    },
    headerLogoContainer: {
      backgroundColor: 'white',
      padding: 6,
      borderRadius: 8,
      border: `1px solid ${colors.gray[200]}`,
    },
    headerLogo: {
      width: '100%',
      height: 32,
      objectFit: 'contain',
      borderRadius: 6,
    },
    headerRight: {
      width: '35%',
      backgroundColor: colors.primary,
      padding: '6 10',
      borderRadius: 6,
    },
    headerTitle: {
      fontSize: 9,
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    headerCenter: {
      width: '30%',
    },
    headerInfo: {
      gap: 4,
    },
    headerInfoItem: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#f2f2f2',
      padding: '3 6',
      borderRadius: 4,
      marginBottom: 2,
    },
    headerValue: {
      fontSize: 9,
      color: colors.primary,
      flex: 1,
    },
    itemContainer: {
      backgroundColor: 'white',
      padding: '4 6',
      borderRadius: 4,
      marginBottom: 4,
      border: `1px solid ${colors.gray[200]}`,
      flexDirection: 'row',
      alignItems: 'center',
      gap: 4,
    },
    // Secciones con anchos específicos para cada campo
    itemSectionSmall: {
      width: '8%',
    },
    itemSectionMedium: {
      width: '12%',
    },
    itemSectionLarge: {
      width: '15%',
    },
    itemSectionXLarge: {
      width: '18%', // Para el campo de actividades que necesita más espacio
    },
    itemLabel: {
      fontSize: 5,
      color: colors.primary,
      fontWeight: 'bold',
      marginBottom: 1,
    },
    itemValue: {
      fontSize: 6,
      color: colors.gray[800],
    },
    footer: {
      position: 'absolute',
      bottom: 25,
      left: 25,
      right: 25,
      borderTop: `1px solid ${colors.gray[200]}`,
      paddingTop: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    footerLeft: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
    },
    footerLogo: {
      width: 100,
      height: 20,
      objectFit: 'contain',
    },
    footerWeb: {
      fontSize: 8,
      color: colors.primary,
      fontWeight: 'bold',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 6,
      bottom: 15,
      right: 25,
      color: colors.gray[800],
    },
  });
};

const CronogramaMtoDocuPDF = ({ equipos, cliente }) => {
  const styles = createStyles(cliente?.primaryColor, cliente?.secondaryColor);
  const headerLogo = cliente?.plantillaType === 'prestador' ? cliente?.imagenurl : cliente?.logoUrl;

  // Función de comparación por fecha
  const compare = (a, b) => {
    const fechaA = new Date(a.fecha);
    const fechaB = new Date(b.fecha);
    return fechaA - fechaB;
  };

  const equiposordenados = equipos ? equipos.sort(compare) : [];

  const Footer = () => (
    <View style={styles.footer} fixed>
      <View style={styles.footerLeft}>
        <Image src={alphalogo} style={styles.footerLogo} />
      </View>
      <View>
        <Text style={styles.footerWeb}>www.alphabiomedica.com</Text>
      </View>
    </View>
  );

  const Header = () => (
    <View style={styles.header} fixed>
      <View style={styles.headerContent}>
        <View style={styles.headerLeft}>
          <View style={styles.headerLogoContainer}>
            <Image src={headerLogo || alphalogo} style={styles.headerLogo} />
          </View>
        </View>
        <View style={styles.headerRight}>
          <Text style={styles.headerTitle}>CRONOGRAMA DE MANTENIMIENTO PREVENTIVO</Text>
        </View>
        <View style={styles.headerCenter}>
          <View style={styles.headerInfo}>
            <View style={styles.headerInfoItem}>
              <Text style={styles.headerValue}>{cliente?.nombre || 'No disponible'}</Text>
            </View>
            <View style={styles.headerInfoItem}>
              <Text style={styles.headerValue}>{cliente?.ccnit || 'No disponible'}</Text>
            </View>
            <View style={styles.headerInfoItem}>
              <Text style={styles.headerValue}>{cliente?.ciudad || 'No disponible'}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  const renderItem = (equipo) => (
    <View style={styles.itemContainer} key={equipo.idinventario}>
      <View style={styles.itemSectionSmall}>
        <Text style={styles.itemLabel}>ID</Text>
        <Text style={styles.itemValue}>{equipo.idinventario}</Text>
      </View>

      <View style={styles.itemSectionLarge}>
        <Text style={styles.itemLabel}>Nombre</Text>
        <Text style={styles.itemValue}>{equipo.nombre}</Text>
      </View>

      <View style={styles.itemSectionMedium}>
        <Text style={styles.itemLabel}>Marca</Text>
        <Text style={styles.itemValue}>{equipo.marca}</Text>
      </View>

      <View style={styles.itemSectionMedium}>
        <Text style={styles.itemLabel}>Serie</Text>
        <Text style={styles.itemValue}>{equipo.serie}</Text>
      </View>

      <View style={styles.itemSectionMedium}>
        <Text style={styles.itemLabel}>Ubicación</Text>
        <Text style={styles.itemValue}>{equipo.ubicacion}</Text>
      </View>

      <View style={styles.itemSectionSmall}>
        <Text style={styles.itemLabel}>Fecha</Text>
        <Text style={styles.itemValue}>{equipo.fecha}</Text>
      </View>

      <View style={styles.itemSectionSmall}>
        <Text style={styles.itemLabel}>Mes</Text>
        <Text style={styles.itemValue}>{equipo.mesnombre}</Text>
      </View>

      <View style={styles.itemSectionSmall}>
        <Text style={styles.itemLabel}>Estado</Text>
        <Text style={styles.itemValue}>{equipo.estado}</Text>
      </View>

      <View style={styles.itemSectionXLarge}>
        <Text style={styles.itemLabel}>Actividades</Text>
        <Text style={styles.itemValue}>{equipo.actividades}</Text>
      </View>
    </View>
  );

  // Dividir los equipos en grupos de 8 para la paginación
  const itemsPerPage = 11;
  const pages = [];
  for (let i = 0; i < equiposordenados.length; i += itemsPerPage) {
    pages.push(equiposordenados.slice(i, i + itemsPerPage));
  }

  return (
    <Document>
      {pages.map((pageItems, pageIndex) => (
        <Page size="A4" style={styles.page} key={pageIndex}>
          <Header />
          {pageItems.map(renderItem)}
          <Footer />
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Página ${pageNumber} de ${totalPages}`
          )} fixed />
        </Page>
      ))}
    </Document>
  );
};

export default CronogramaMtoDocuPDF;